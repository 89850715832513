/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import './homePage.scss';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // n'oubliez pas d'importer les styles
import CookieConsent from 'react-cookie-consent';
// Remplacez ces imports par les chemins vers vos images réelles
import image1 from '../../assets/images/homePage/zovGlobal.jpg';
import image2 from '../../assets/images/homePage/kuhni.jpg';
import image3 from '../../assets/images/homePage/Wardrobe.jpg';
import TypeFormEmbed from '../newsLetter/TypeFormEmbed';

// Ajoutez vos images de bannière ici
const bannerImages = [
  image1,
  image2,
  image3,
];

function HomePage() {
  return (
    <div className="home-page">
      <Carousel
        showThumbs={false}
        showStatus={false}
        autoPlay
        infiniteLoop
        useKeyboardArrows
        dynamicHeight
        showIndicators={false}
        className="main-banner-carousel"
      >
        {bannerImages.map((image, index) => (
          <div key={index} className="carousel-slide">
            <img src={image} alt={`Slide ${index}`} />
          </div>
        ))}
      </Carousel>
      <div className="carousel-center-text">
        <TypeFormEmbed />
        ZOV -
        Furniture for life
      </div>
      <div className="discover-button-wrapper">
        <a href="/concepts" className="discover-button">Discover our products</a>
      </div>

      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="userConsent"
        style={{ background: '#2B373B', textAlign: 'center' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{' '}
        <a href="/privacy-policy" style={{ color: 'white', fontSize: '10px' }}>Learn more</a>
      </CookieConsent>
    </div>
  );
}

export default HomePage;
