/* eslint-disable react/no-array-index-key */
import {
  Card, CardActionArea, CardMedia, Grid, Typography, Box
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import './allConcepts.scss';

import Kitchen from '../../assets/images/conceptMiniature/kichenPreview.jpg';
import LivingRoom from '../../assets/images/conceptMiniature/livingRoom.jpg';
import Office from '../../assets/images/conceptMiniature/Office.jpg';
import Bedroom from '../../assets/images/conceptMiniature/bedroom.jpg';
import Bathroom from '../../assets/images/conceptMiniature/Bathroom.jpg';
import InteriorDoors from '../../assets/images/conceptMiniature/Door.jpg';
import Florencia from '../../assets/images/conceptMiniature/Florencia.jpg';
import Closet from '../../assets/images/conceptMiniature/closet.jpg';
import Accessories from '../../assets/images/conceptMiniature/homeAccessories.jpg';
import Hotel from '../../assets/images/conceptMiniature/hotelFurniture.jpg';
import InteriorElements from '../../assets/images/conceptMiniature/interiorElement.jpg';
import Materials from '../../assets/images/conceptMiniature/liftSystem.jpg';

const concepts = [
  { title: 'Kitchens', img: Kitchen, link: '/kitchens' },
  { title: 'Offices and cabinet furniture', img: Office, link: '/office' },
  { title: 'Hotel furniture', img: Hotel, link: '/hotel-furniture' },
  { title: 'Closet and sliding systems', img: Closet, link: '/closet-and-sliding-systems' },
  { title: 'Living Rooms', img: LivingRoom, link: '/livingRooms' },
  { title: 'Bedrooms', img: Bedroom, link: '/bedrooms' },
  { title: 'Bathrooms', img: Bathroom, link: '/bathrooms' },
  { title: 'Interior doors', img: InteriorDoors, link: '/interior-doors' },
  { title: 'Table and chairs', img: Florencia, link: '/table-and-chairs' },
  { title: 'Home accessories', img: Accessories, link: '/home-accessories' },
  { title: 'Materials, fitting and accessories', img: Materials, link: '/materials-fitting-and-accessories' },
  { title: 'Interior elements', img: InteriorElements, link: '/interior-elements' },

];

function ConceptsPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div className="allConcepts">
      <Box sx={{
        display: 'flex',
        flexGrow: 1,
        paddingTop: isMobile ? '6rem' : '3rem',
        paddingBottom: isMobile ? '3rem' : '0',
        minHeight: '100vh',
        width: '100vw',
        justifyContent: 'space-evenly',
      }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            justifyContent: 'center', width: '100%', paddingTop: '1rem'
          }}
        >

          {concepts.map((concept, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  maxWidth: '100%',
                  transition: '0.3s',
                  '&:hover': {
                    transform: isMobile ? 'none' : 'scale(1.03)',
                    boxShadow: isMobile ? 'none' : '0 4px 20px 0 rgba(0,0,0,0.12)',
                  },
                }}
              >
                <CardActionArea href={concept.link}>
                  <CardMedia
                    component="img"
                    height="220"
                    image={concept.img}
                    alt={concept.title}
                    sx={{
                      transition: 'transform 0.3s',
                      '&:hover': {
                        transform: isMobile ? 'none' : 'scale(1)',
                      },
                    }}
                  />
                  <Typography gutterBottom variant="h6" component="h15">
                    {concept.title}
                  </Typography>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

export default ConceptsPage;
