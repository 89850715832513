/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react';

import '../Kitchens/kitchens.scss';

import ProductModal from '../productModal/productModal';

// alice imports

import Alice1 from '../../../assets/images/concepts/tablesChairs/alice/1.jpg';
import Alice2 from '../../../assets/images/concepts/tablesChairs/alice/2.jpg';

// ceasar imports

import Ceasar1 from '../../../assets/images/concepts/tablesChairs/ceasar/1.jpg';
import Ceasar2 from '../../../assets/images/concepts/tablesChairs/ceasar/2.jpg';
import Ceasar3 from '../../../assets/images/concepts/tablesChairs/ceasar/3.jpg';
import Ceasar4 from '../../../assets/images/concepts/tablesChairs/ceasar/4.jpg';
import Ceasar5 from '../../../assets/images/concepts/tablesChairs/ceasar/5.jpg';
import Ceasar6 from '../../../assets/images/concepts/tablesChairs/ceasar/6.jpg';
import Ceasar7 from '../../../assets/images/concepts/tablesChairs/ceasar/7.jpg';
import Ceasar8 from '../../../assets/images/concepts/tablesChairs/ceasar/8.jpg';

// christopher imports

import Christopher1 from '../../../assets/images/concepts/tablesChairs/christopher/1.jpg';
import Christopher2 from '../../../assets/images/concepts/tablesChairs/christopher/2.jpg';
import Christopher3 from '../../../assets/images/concepts/tablesChairs/christopher/3.jpg';
import Christopher4 from '../../../assets/images/concepts/tablesChairs/christopher/4.jpg';
import Christopher5 from '../../../assets/images/concepts/tablesChairs/christopher/5.jpg';

// christopherUpholsteredSeat imports

import ChristopherUpholsteredSeat1 from '../../../assets/images/concepts/tablesChairs/christopherUpholsteredSeat/1.jpg';
import ChristopherUpholsteredSeat2 from '../../../assets/images/concepts/tablesChairs/christopherUpholsteredSeat/2.jpg';
import ChristopherUpholsteredSeat3 from '../../../assets/images/concepts/tablesChairs/christopherUpholsteredSeat/3.jpg';
import ChristopherUpholsteredSeat4 from '../../../assets/images/concepts/tablesChairs/christopherUpholsteredSeat/4.jpg';
import ChristopherUpholsteredSeat5 from '../../../assets/images/concepts/tablesChairs/christopherUpholsteredSeat/5.jpg';
import ChristopherUpholsteredSeat6 from '../../../assets/images/concepts/tablesChairs/christopherUpholsteredSeat/6.jpg';
import ChristopherUpholsteredSeat7 from '../../../assets/images/concepts/tablesChairs/christopherUpholsteredSeat/7.jpg';
import ChristopherUpholsteredSeat8 from '../../../assets/images/concepts/tablesChairs/christopherUpholsteredSeat/8.jpg';

// florence imports

import Florence1 from '../../../assets/images/concepts/tablesChairs/florence/1.jpg';
import Florence2 from '../../../assets/images/concepts/tablesChairs/florence/2.jpg';
import Florence3 from '../../../assets/images/concepts/tablesChairs/florence/3.jpg';

// ita imports

import Ita1 from '../../../assets/images/concepts/tablesChairs/ita/1.jpg';
import Ita2 from '../../../assets/images/concepts/tablesChairs/ita/2.jpg';
import Ita3 from '../../../assets/images/concepts/tablesChairs/ita/3.jpg';
import Ita4 from '../../../assets/images/concepts/tablesChairs/ita/4.jpg';
import Ita5 from '../../../assets/images/concepts/tablesChairs/ita/5.jpg';
import Ita6 from '../../../assets/images/concepts/tablesChairs/ita/6.jpg';
import Ita7 from '../../../assets/images/concepts/tablesChairs/ita/7.jpg';
import Ita8 from '../../../assets/images/concepts/tablesChairs/ita/8.jpg';

// loft imports

import Loft1 from '../../../assets/images/concepts/tablesChairs/loft/1.jpg';
import Loft2 from '../../../assets/images/concepts/tablesChairs/loft/2.jpg';
import Loft3 from '../../../assets/images/concepts/tablesChairs/loft/3.jpg';
import Loft4 from '../../../assets/images/concepts/tablesChairs/loft/4.jpg';
import Loft5 from '../../../assets/images/concepts/tablesChairs/loft/5.jpg';
import Loft6 from '../../../assets/images/concepts/tablesChairs/loft/6.jpg';
import Loft7 from '../../../assets/images/concepts/tablesChairs/loft/7.jpg';
import Loft8 from '../../../assets/images/concepts/tablesChairs/loft/8.jpg';
import Loft9 from '../../../assets/images/concepts/tablesChairs/loft/9.jpg';
import Loft10 from '../../../assets/images/concepts/tablesChairs/loft/10.jpg';
import Loft11 from '../../../assets/images/concepts/tablesChairs/loft/11.jpg';
import Loft12 from '../../../assets/images/concepts/tablesChairs/loft/12.jpg';
import Loft13 from '../../../assets/images/concepts/tablesChairs/loft/13.jpg';
import Loft14 from '../../../assets/images/concepts/tablesChairs/loft/14.jpg';
import Loft15 from '../../../assets/images/concepts/tablesChairs/loft/15.jpg';
import Loft16 from '../../../assets/images/concepts/tablesChairs/loft/16.jpg';
import Loft17 from '../../../assets/images/concepts/tablesChairs/loft/17.jpg';
import Loft18 from '../../../assets/images/concepts/tablesChairs/loft/18.jpg';
import Loft19 from '../../../assets/images/concepts/tablesChairs/loft/19.jpg';
import Loft20 from '../../../assets/images/concepts/tablesChairs/loft/20.jpg';
import Loft21 from '../../../assets/images/concepts/tablesChairs/loft/21.jpg';
import Loft22 from '../../../assets/images/concepts/tablesChairs/loft/22.jpg';
import Loft23 from '../../../assets/images/concepts/tablesChairs/loft/23.jpg';
import Loft24 from '../../../assets/images/concepts/tablesChairs/loft/24.jpg';
import Loft25 from '../../../assets/images/concepts/tablesChairs/loft/25.jpg';
import Loft26 from '../../../assets/images/concepts/tablesChairs/loft/26.jpg';

// lord imports

import Lord1 from '../../../assets/images/concepts/tablesChairs/lord/1.jpg';
import Lord2 from '../../../assets/images/concepts/tablesChairs/lord/2.jpg';
import Lord3 from '../../../assets/images/concepts/tablesChairs/lord/3.jpg';
import Lord4 from '../../../assets/images/concepts/tablesChairs/lord/4.jpg';
import Lord5 from '../../../assets/images/concepts/tablesChairs/lord/5.jpg';
import Lord6 from '../../../assets/images/concepts/tablesChairs/lord/6.jpg';
import Lord7 from '../../../assets/images/concepts/tablesChairs/lord/7.jpg';

// milan imports

import Milan1 from '../../../assets/images/concepts/tablesChairs/milan/1.jpg';
import Milan2 from '../../../assets/images/concepts/tablesChairs/milan/2.jpg';

// milanc import

import Milanc1 from '../../../assets/images/concepts/tablesChairs/milanc/1.jpg';
import Milanc2 from '../../../assets/images/concepts/tablesChairs/milanc/2.jpg';
import Milanc3 from '../../../assets/images/concepts/tablesChairs/milanc/3.jpg';
import Milanc4 from '../../../assets/images/concepts/tablesChairs/milanc/4.jpg';
import Milanc5 from '../../../assets/images/concepts/tablesChairs/milanc/5.jpg';
import Milanc6 from '../../../assets/images/concepts/tablesChairs/milanc/6.jpg';
import Milanc7 from '../../../assets/images/concepts/tablesChairs/milanc/7.jpg';

// modern imports

import Modern1 from '../../../assets/images/concepts/tablesChairs/modern/1.jpg';
import Modern2 from '../../../assets/images/concepts/tablesChairs/modern/2.jpg';
import Modern3 from '../../../assets/images/concepts/tablesChairs/modern/3.jpg';

// palermo imports

import Palermo1 from '../../../assets/images/concepts/tablesChairs/palermo/1.jpg';
import Palermo2 from '../../../assets/images/concepts/tablesChairs/palermo/2.jpg';
import Palermo3 from '../../../assets/images/concepts/tablesChairs/palermo/3.jpg';

// rome imports

import Rome1 from '../../../assets/images/concepts/tablesChairs/rome/1.jpg';
import Rome2 from '../../../assets/images/concepts/tablesChairs/rome/2.jpg';

// verona imports

import Verona1 from '../../../assets/images/concepts/tablesChairs/verona/1.jpg';
import Verona2 from '../../../assets/images/concepts/tablesChairs/verona/2.jpg';

// weekend imports

import Weekend1 from '../../../assets/images/concepts/tablesChairs/weekend/1.jpg';
import Weekend2 from '../../../assets/images/concepts/tablesChairs/weekend/2.jpg';
import Weekend3 from '../../../assets/images/concepts/tablesChairs/weekend/3.jpg';

const products = [
  {
    id: 1,
    title: 'Alice',
    previewUrl: Alice1, // Remplacez par le chemin de votre image
    imageUrls: [Alice1, Alice2],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },
  {
    id: 2,
    title: 'Ceasar',
    previewUrl: Ceasar1, // Remplacez par le chemin de votre image
    imageUrls: [Ceasar1, Ceasar2, Ceasar3, Ceasar4, Ceasar5, Ceasar6, Ceasar7, Ceasar8],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },

  {
    id: 3,
    title: 'Christopher',
    previewUrl: Christopher1, // Remplacez par le chemin de votre image
    imageUrls: [Christopher1, Christopher2, Christopher3, Christopher4, Christopher5],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },

  {
    id: 4,
    title: 'Christopher Upholstered Seat',
    previewUrl: ChristopherUpholsteredSeat1, // Remplacez par le chemin de votre image
    imageUrls: [ChristopherUpholsteredSeat1, ChristopherUpholsteredSeat2, ChristopherUpholsteredSeat3, ChristopherUpholsteredSeat4, ChristopherUpholsteredSeat5, ChristopherUpholsteredSeat6, ChristopherUpholsteredSeat7, ChristopherUpholsteredSeat8],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },

  {
    id: 5,
    title: 'Florence',
    previewUrl: Florence1, // Remplacez par le chemin de votre image
    imageUrls: [Florence1, Florence2, Florence3],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },

  {
    id: 6,
    title: 'Ita',
    previewUrl: Ita1, // Remplacez par le chemin de votre image
    imageUrls: [Ita1, Ita2, Ita3, Ita4, Ita5, Ita6, Ita7, Ita8],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.',
  },

  {
    id: 7,
    title: 'Loft',
    previewUrl: Loft1, // Remplacez par le chemin de votre image
    imageUrls: [Loft1, Loft2, Loft3, Loft4, Loft5, Loft6, Loft7, Loft8, Loft9, Loft10, Loft11, Loft12, Loft13, Loft14, Loft15, Loft16, Loft17, Loft18, Loft19, Loft20, Loft21, Loft22, Loft23, Loft24, Loft25, Loft26],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.',
  },

  {
    id: 8,
    title: 'Lord',
    previewUrl: Lord1, // Remplacez par le chemin de votre image
    imageUrls: [Lord1, Lord2, Lord3, Lord4, Lord5, Lord6, Lord7],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.',
  },

  {
    id: 9,
    title: 'Milan',
    previewUrl: Milan1, // Remplacez par le chemin de votre image
    imageUrls: [Milan1, Milan2],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed.',
  },

  {
    id: 10,
    title: 'Milanc',
    previewUrl: Milanc1, // Remplacez par le chemin de votre image
    imageUrls: [Milanc1, Milanc2, Milanc3, Milanc4, Milanc5, Milanc6, Milanc7],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim.',
  },

  {
    id: 11,
    title: 'Modern',
    previewUrl: Modern1, // Remplacez par le chemin de votre image
    imageUrls: [Modern1, Modern2, Modern3],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim.',
  },

  {
    id: 12,
    title: 'Palermo',
    previewUrl: Palermo1, // Remplacez par le chemin de votre image
    imageUrls: [Palermo1, Palermo2, Palermo3],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim.',
  },

  {
    id: 13,
    title: 'Rome',
    previewUrl: Rome1, // Remplacez par le chemin de votre image
    imageUrls: [Rome1, Rome2],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim.',
  },

  {
    id: 14,
    title: 'Verona',
    previewUrl: Verona1, // Remplacez par le chemin de votre image
    imageUrls: [Verona1, Verona2],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim.',
  },

  {
    id: 15,
    title: 'Weekend',
    previewUrl: Weekend1, // Remplacez par le chemin de votre image
    imageUrls: [Weekend1, Weekend2, Weekend3],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim.',
  },
];

function ProductDisplay() {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };
  return (
    <div className="product-display-container">
      {products.map((product) => (
        <div className="product-card" key={product.id} onClick={() => handleProductClick(product)}>
          <img src={product.previewUrl} alt={product.title} className="product-image" />
          <div className="product-info">
            <h2 className="product-title">{product.title}</h2>
          </div>
        </div>
      ))}
      {isModalOpen && <ProductModal product={selectedProduct} onClose={handleCloseModal} />}

    </div>
  );
}

export default ProductDisplay;
