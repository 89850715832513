/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react';

import '../Kitchens/kitchens.scss';

// dion import
import Dion1 from '../../../assets/images/concepts/bathroom/dion/1.jpg';
import Dion2 from '../../../assets/images/concepts/bathroom/dion/2.jpg';
import Dion3 from '../../../assets/images/concepts/bathroom/dion/3.jpg';
import Dion4 from '../../../assets/images/concepts/bathroom/dion/4.jpg';
import Dion5 from '../../../assets/images/concepts/bathroom/dion/5.jpg';
import Dion6 from '../../../assets/images/concepts/bathroom/dion/6.jpg';
import Dion7 from '../../../assets/images/concepts/bathroom/dion/7.jpg';
import Dion8 from '../../../assets/images/concepts/bathroom/dion/8.jpg';
import Dion9 from '../../../assets/images/concepts/bathroom/dion/9.jpg';

// kito import
import Kito1 from '../../../assets/images/concepts/bathroom/kito/1.jpg';
import Kito2 from '../../../assets/images/concepts/bathroom/kito/2.jpg';
import Kito3 from '../../../assets/images/concepts/bathroom/kito/3.jpg';
import Kito4 from '../../../assets/images/concepts/bathroom/kito/4.jpg';
import Kito5 from '../../../assets/images/concepts/bathroom/kito/5.jpg';
import Kito6 from '../../../assets/images/concepts/bathroom/kito/6.jpg';
import Kito7 from '../../../assets/images/concepts/bathroom/kito/7.jpg';
import Kito9 from '../../../assets/images/concepts/bathroom/kito/9.jpg';
import Kito10 from '../../../assets/images/concepts/bathroom/kito/10.jpg';
import Kito11 from '../../../assets/images/concepts/bathroom/kito/11.jpg';
import Kito12 from '../../../assets/images/concepts/bathroom/kito/12.jpg';
import Kito13 from '../../../assets/images/concepts/bathroom/kito/13.jpg';
import Kito14 from '../../../assets/images/concepts/bathroom/kito/14.jpg';

// lira import
import Lira1 from '../../../assets/images/concepts/bathroom/lira/1.jpg';
import Lira2 from '../../../assets/images/concepts/bathroom/lira/2.jpg';
import Lira3 from '../../../assets/images/concepts/bathroom/lira/3.jpg';
import Lira4 from '../../../assets/images/concepts/bathroom/lira/4.jpg';
import Lira5 from '../../../assets/images/concepts/bathroom/lira/5.jpg';
import Lira6 from '../../../assets/images/concepts/bathroom/lira/6.jpg';
import Lira7 from '../../../assets/images/concepts/bathroom/lira/7.jpg';
import Lira8 from '../../../assets/images/concepts/bathroom/lira/8.jpg';
import Lira9 from '../../../assets/images/concepts/bathroom/lira/9.jpg';

// origami import
import Origami1 from '../../../assets/images/concepts/bathroom/origami/1.jpg';
import Origami2 from '../../../assets/images/concepts/bathroom/origami/2.jpg';
import Origami3 from '../../../assets/images/concepts/bathroom/origami/3.jpg';

// parma import
import Parma1 from '../../../assets/images/concepts/bathroom/parma/1.jpg';
import Parma2 from '../../../assets/images/concepts/bathroom/parma/2.jpg';
import Parma3 from '../../../assets/images/concepts/bathroom/parma/3.jpg';
import Parma4 from '../../../assets/images/concepts/bathroom/parma/4.jpg';
import Parma5 from '../../../assets/images/concepts/bathroom/parma/5.jpg';
import Parma6 from '../../../assets/images/concepts/bathroom/parma/6.jpg';

// piedmont import

import Piedmont1 from '../../../assets/images/concepts/bathroom/piedmont/1.jpg';
import Piedmont2 from '../../../assets/images/concepts/bathroom/piedmont/2.jpg';
import Piedmont3 from '../../../assets/images/concepts/bathroom/piedmont/3.jpg';
import Piedmont4 from '../../../assets/images/concepts/bathroom/piedmont/4.jpg';
import Piedmont5 from '../../../assets/images/concepts/bathroom/piedmont/5.jpg';
import Piedmont6 from '../../../assets/images/concepts/bathroom/piedmont/6.jpg';
import Piedmont7 from '../../../assets/images/concepts/bathroom/piedmont/7.jpg';

// provence import

import Provence1 from '../../../assets/images/concepts/bathroom/provence/1.jpg';
import Provence2 from '../../../assets/images/concepts/bathroom/provence/2.jpg';
import Provence3 from '../../../assets/images/concepts/bathroom/provence/3.jpg';
import Provence4 from '../../../assets/images/concepts/bathroom/provence/4.jpg';
import Provence5 from '../../../assets/images/concepts/bathroom/provence/5.jpg';
import Provence6 from '../../../assets/images/concepts/bathroom/provence/6.jpg';
import Provence7 from '../../../assets/images/concepts/bathroom/provence/7.jpg';
import Provence8 from '../../../assets/images/concepts/bathroom/provence/8.jpg';
import Provence9 from '../../../assets/images/concepts/bathroom/provence/9.jpg';
import Provence10 from '../../../assets/images/concepts/bathroom/provence/10.jpg';
import Provence11 from '../../../assets/images/concepts/bathroom/provence/11.jpg';

// viola import

import Viola1 from '../../../assets/images/concepts/bathroom/viola/1.jpg';
import Viola2 from '../../../assets/images/concepts/bathroom/viola/2.jpg';
import Viola3 from '../../../assets/images/concepts/bathroom/viola/3.jpg';

import ProductModal from '../productModal/productModal';

const products = [
  {
    id: 1,
    title: 'Dion',
    previewUrl: Dion1, // Remplacez par le chemin de votre image
    imageUrls: [Dion1, Dion2, Dion3, Dion4, Dion5, Dion6, Dion7, Dion8, Dion9],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },
  {
    id: 2, title: 'Kito', previewUrl: Kito1, imageUrls: [Kito1, Kito2, Kito3, Kito4, Kito5, Kito6, Kito7, Kito9, Kito10, Kito11, Kito12, Kito13, Kito14], description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },
  {
    id: 3, title: 'Lira', previewUrl: Lira1, imageUrls: [Lira1, Lira2, Lira3, Lira4, Lira5, Lira6, Lira7, Lira8, Lira9], description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },
  {
    id: 4, title: 'Origami', previewUrl: Origami1, imageUrls: [Origami1, Origami2, Origami3], description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },
  {
    id: 5, title: 'Parma', previewUrl: Parma1, imageUrls: [Parma1, Parma2, Parma3, Parma4, Parma5, Parma6], description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },
  {
    id: 6, title: 'Piedmont', previewUrl: Piedmont1, imageUrls: [Piedmont1, Piedmont2, Piedmont3, Piedmont4, Piedmont5, Piedmont6, Piedmont7], description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },
  {
    id: 7, title: 'Provence', previewUrl: Provence1, imageUrls: [Provence1, Provence2, Provence3, Provence4, Provence5, Provence6, Provence7, Provence8, Provence9, Provence10, Provence11], description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },
  {
    id: 8, title: 'Viola', previewUrl: Viola1, imageUrls: [Viola1, Viola2, Viola3], description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  }

];

function ProductDisplay() {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };
  return (
    <div className="product-display-container">
      {products.map((product) => (
        <div className="product-card" key={product.id} onClick={() => handleProductClick(product)}>
          <img src={product.previewUrl} alt={product.title} className="product-image" />
          <div className="product-info">
            <h2 className="product-title">{product.title}</h2>
          </div>
        </div>
      ))}
      {isModalOpen && <ProductModal product={selectedProduct} onClose={handleCloseModal} />}

    </div>
  );
}

export default ProductDisplay;
