/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react';

import '../Kitchens/kitchens.scss';

import HomeAccessoires1 from '../../../assets/images/concepts/homeaccessoires/1.jpg';
import HomeAccessoires2 from '../../../assets/images/concepts/homeaccessoires/2.jpg';
import HomeAccessoires3 from '../../../assets/images/concepts/homeaccessoires/3.jpg';
import HomeAccessoires4 from '../../../assets/images/concepts/homeaccessoires/4.jpg';
import HomeAccessoires5 from '../../../assets/images/concepts/homeaccessoires/5.jpg';
import HomeAccessoires6 from '../../../assets/images/concepts/homeaccessoires/6.jpg';
import HomeAccessoires7 from '../../../assets/images/concepts/homeaccessoires/7.jpg';
import HomeAccessoires8 from '../../../assets/images/concepts/homeaccessoires/8.jpg';
import HomeAccessoires9 from '../../../assets/images/concepts/homeaccessoires/9.jpg';
import HomeAccessoires10 from '../../../assets/images/concepts/homeaccessoires/10.jpg';
import HomeAccessoires11 from '../../../assets/images/concepts/homeaccessoires/11.jpg';

import ProductModal from '../productModal/productModal';

const products = [
  {
    id: 1,
    title: 'Home accessories',
    previewUrl: HomeAccessoires1, // Remplacez par le chemin de votre image
    imageUrls: [HomeAccessoires1, HomeAccessoires2, HomeAccessoires3, HomeAccessoires4, HomeAccessoires5, HomeAccessoires6, HomeAccessoires7, HomeAccessoires8, HomeAccessoires9, HomeAccessoires10, HomeAccessoires11],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },
];

function ProductDisplay() {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };
  return (
    <div className="product-display-container">
      {products.map((product) => (
        <div className="product-card" key={product.id} onClick={() => handleProductClick(product)}>
          <img src={product.previewUrl} alt={product.title} className="product-image" />
          <div className="product-info">
            <h2 className="product-title">{product.title}</h2>
          </div>
        </div>
      ))}
      {isModalOpen && <ProductModal product={selectedProduct} onClose={handleCloseModal} />}

    </div>
  );
}
export default ProductDisplay;
