/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react';

import '../Kitchens/kitchens.scss';

import ProductModal from '../productModal/productModal';

// amber import

import Amber1 from '../../../assets/images/concepts/office/amber/1.jpg';
import Amber2 from '../../../assets/images/concepts/office/amber/2.jpg';
import Amber3 from '../../../assets/images/concepts/office/amber/3.jpg';
import Amber4 from '../../../assets/images/concepts/office/amber/4.jpg';

// arthurhomeoffice import

import ArthurHomeOffice1 from '../../../assets/images/concepts/office/arthurhomeoffice/1.jpg';
import ArthurHomeOffice2 from '../../../assets/images/concepts/office/arthurhomeoffice/2.jpg';
import ArthurHomeOffice3 from '../../../assets/images/concepts/office/arthurhomeoffice/3.jpg';
import ArthurHomeOffice4 from '../../../assets/images/concepts/office/arthurhomeoffice/4.jpg';
import ArthurHomeOffice5 from '../../../assets/images/concepts/office/arthurhomeoffice/5.jpg';
import ArthurHomeOffice6 from '../../../assets/images/concepts/office/arthurhomeoffice/6.jpg';

// viola import

import Viola1 from '../../../assets/images/concepts/office/viola/1.jpg';
import Viola2 from '../../../assets/images/concepts/office/viola/2.jpg';
import Viola3 from '../../../assets/images/concepts/office/viola/3.jpg';
import Viola4 from '../../../assets/images/concepts/office/viola/4.jpg';
import Viola5 from '../../../assets/images/concepts/office/viola/5.jpg';
import Viola6 from '../../../assets/images/concepts/office/viola/6.jpg';

const products = [
  {
    id: 1,
    title: 'Amber',
    previewUrl: Amber1, // Remplacez par le chemin de votre image
    imageUrls: [Amber1, Amber2, Amber3, Amber4], // Remplacez par les chemins de vos images
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },
  {
    id: 2,
    title: 'Arthur Home Office',
    previewUrl: ArthurHomeOffice1, // Remplacez par le chemin de votre image
    imageUrls: [ArthurHomeOffice1, ArthurHomeOffice2, ArthurHomeOffice3, ArthurHomeOffice4, ArthurHomeOffice5, ArthurHomeOffice6], // Remplacez par les chemins de vos images
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },
  {
    id: 3,
    title: 'Viola',
    previewUrl: Viola1, // Remplacez par le chemin de votre image
    imageUrls: [Viola1, Viola2, Viola3, Viola4, Viola5, Viola6], // Remplacez par les chemins de vos images
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  }

];

function ProductDisplay() {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };
  return (
    <div className="product-display-container">
      {products.map((product) => (
        <div className="product-card" key={product.id} onClick={() => handleProductClick(product)}>
          <img src={product.previewUrl} alt={product.title} className="product-image" />
          <div className="product-info">
            <h2 className="product-title">{product.title}</h2>
          </div>
        </div>
      ))}
      {isModalOpen && <ProductModal product={selectedProduct} onClose={handleCloseModal} />}

    </div>
  );
}

export default ProductDisplay;
