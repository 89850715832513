/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react';

import '../Kitchens/kitchens.scss';

import ProductModal from '../productModal/productModal';

// slidindoorsystemms import

import SlidingDoorSystemms2 from '../../../assets/images/concepts/wardrobe/slidingdoorsystemms/2.jpg';
import SlidingDoorSystemms3 from '../../../assets/images/concepts/wardrobe/slidingdoorsystemms/3.jpg';
import SlidingDoorSystemms4 from '../../../assets/images/concepts/wardrobe/slidingdoorsystemms/4.jpg';
import SlidingDoorSystemms5 from '../../../assets/images/concepts/wardrobe/slidingdoorsystemms/5.jpg';
import SlidingDoorSystemms6 from '../../../assets/images/concepts/wardrobe/slidingdoorsystemms/6.jpg';
import SlidingDoorSystemms7 from '../../../assets/images/concepts/wardrobe/slidingdoorsystemms/7.jpg';
import SlidingDoorSystemms8 from '../../../assets/images/concepts/wardrobe/slidingdoorsystemms/8.jpg';
import SlidingDoorSystemms9 from '../../../assets/images/concepts/wardrobe/slidingdoorsystemms/9.jpg';
import SlidingDoorSystemms10 from '../../../assets/images/concepts/wardrobe/slidingdoorsystemms/10.jpg';
import SlidingDoorSystemms11 from '../../../assets/images/concepts/wardrobe/slidingdoorsystemms/11.jpg';
import SlidingDoorSystemms12 from '../../../assets/images/concepts/wardrobe/slidingdoorsystemms/12.jpg';

// slidindoorsystemnova import

import SlidingDoorSystemNova1 from '../../../assets/images/concepts/wardrobe/slidingdoorsystemnova/1.jpg';
import SlidingDoorSystemNova2 from '../../../assets/images/concepts/wardrobe/slidingdoorsystemnova/2.jpg';
import SlidingDoorSystemNova3 from '../../../assets/images/concepts/wardrobe/slidingdoorsystemnova/3.jpg';
import SlidingDoorSystemNova4 from '../../../assets/images/concepts/wardrobe/slidingdoorsystemnova/4.jpg';
import SlidingDoorSystemNova5 from '../../../assets/images/concepts/wardrobe/slidingdoorsystemnova/5.jpg';

// slidindoorsystemnslimline import

import SlidingDoorSystemSlimline1 from '../../../assets/images/concepts/wardrobe/slidingdoorsystemslimline/1.jpg';
import SlidingDoorSystemSlimline3 from '../../../assets/images/concepts/wardrobe/slidingdoorsystemslimline/3.jpg';
import SlidingDoorSystemSlimline4 from '../../../assets/images/concepts/wardrobe/slidingdoorsystemslimline/4.jpg';
import SlidingDoorSystemSlimline6 from '../../../assets/images/concepts/wardrobe/slidingdoorsystemslimline/6.jpg';

// suspendedslidingsystmair import

import SuspendedSlidingSystemAir1 from '../../../assets/images/concepts/wardrobe/suspendedslidingsystemair/1.jpg';
import SuspendedSlidingSystemAir2 from '../../../assets/images/concepts/wardrobe/suspendedslidingsystemair/2.jpg';
import SuspendedSlidingSystemAir3 from '../../../assets/images/concepts/wardrobe/suspendedslidingsystemair/3.jpg';
import SuspendedSlidingSystemAir4 from '../../../assets/images/concepts/wardrobe/suspendedslidingsystemair/4.jpg';

// suspendedslidingsystemairsoft import

import SuspendedSlidingSystemAirSoft1 from '../../../assets/images/concepts/wardrobe/suspendedslidingsystemairsoft/1.jpg';
import SuspendedSlidingSystemAirSoft2 from '../../../assets/images/concepts/wardrobe/suspendedslidingsystemairsoft/2.jpg';
import SuspendedSlidingSystemAirSoft3 from '../../../assets/images/concepts/wardrobe/suspendedslidingsystemairsoft/3.jpg';
import SuspendedSlidingSystemAirSoft4 from '../../../assets/images/concepts/wardrobe/suspendedslidingsystemairsoft/4.jpg';

// wardrobesandwardrobesystemswdb_wdbwl import

import WardrobesAndWardrobeSystemsWdb_Wdbwl1 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/1.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl3 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/3.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl5 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/5.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl7 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/7.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl8 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/8.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl9 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/9.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl11 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/11.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl12 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/12.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl15 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/15.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl16 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/16.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl17 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/17.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl18 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/18.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl20 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/20.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl21 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/21.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl24 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/24.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl27 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/27.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl28 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/28.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl29 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/29.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl31 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/31.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl32 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/32.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl33 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/33.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl34 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/34.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl35 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/35.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl38 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/38.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl41 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/41.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl42 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/42.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl43 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/43.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl44 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/44.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl45 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/45.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl47 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/47.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl48 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/48.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl49 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/49.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl51 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/51.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl53 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/53.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl54 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/54.jpg';
import WardrobesAndWardrobeSystemsWdb_Wdbwl55 from '../../../assets/images/concepts/wardrobe/wardrobesandwardrobesystemswdb_wdbwl/55.jpg';

// wardrobeshelvingsystems import

import WardrobesHelvingSystems1 from '../../../assets/images/concepts/wardrobe/wardrobeshelvingsystems/1.jpg';
import WardrobesHelvingSystems2 from '../../../assets/images/concepts/wardrobe/wardrobeshelvingsystems/2.jpg';
import WardrobesHelvingSystems3 from '../../../assets/images/concepts/wardrobe/wardrobeshelvingsystems/3.jpg';
import WardrobesHelvingSystems5 from '../../../assets/images/concepts/wardrobe/wardrobeshelvingsystems/5.jpg';
import WardrobesHelvingSystems6 from '../../../assets/images/concepts/wardrobe/wardrobeshelvingsystems/6.jpg';
import WardrobesHelvingSystems7 from '../../../assets/images/concepts/wardrobe/wardrobeshelvingsystems/7.jpg';
import WardrobesHelvingSystems9 from '../../../assets/images/concepts/wardrobe/wardrobeshelvingsystems/9.jpg';
import WardrobesHelvingSystems10 from '../../../assets/images/concepts/wardrobe/wardrobeshelvingsystems/10.jpg';
import WardrobesHelvingSystems11 from '../../../assets/images/concepts/wardrobe/wardrobeshelvingsystems/11.jpg';
import WardrobesHelvingSystems12 from '../../../assets/images/concepts/wardrobe/wardrobeshelvingsystems/12.jpg';

const products = [
  {
    id: 1,
    title: 'Sliding door system MS',
    previewUrl: SlidingDoorSystemms2, // Remplacez par le chemin de votre image
    imageUrls: [SlidingDoorSystemms2, SlidingDoorSystemms3, SlidingDoorSystemms4, SlidingDoorSystemms5, SlidingDoorSystemms6, SlidingDoorSystemms7, SlidingDoorSystemms8, SlidingDoorSystemms9, SlidingDoorSystemms10, SlidingDoorSystemms11, SlidingDoorSystemms12],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },
  {
    id: 2,
    title: 'Sliding door system Nova',
    previewUrl: SlidingDoorSystemNova1, // Remplacez par le chemin de votre image
    imageUrls: [SlidingDoorSystemNova1, SlidingDoorSystemNova2, SlidingDoorSystemNova3, SlidingDoorSystemNova4, SlidingDoorSystemNova5],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },
  {
    id: 3,
    title: 'Sliding door system Slim line',
    previewUrl: SlidingDoorSystemSlimline1, // Remplacez par le chemin de votre image
    imageUrls: [SlidingDoorSystemSlimline1, SlidingDoorSystemSlimline3, SlidingDoorSystemSlimline4, SlidingDoorSystemSlimline6],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },
  {
    id: 4,
    title: 'Suspended sliding system Air',
    previewUrl: SuspendedSlidingSystemAir1, // Remplacez par le chemin de votre image
    imageUrls: [SuspendedSlidingSystemAir1, SuspendedSlidingSystemAir2, SuspendedSlidingSystemAir3, SuspendedSlidingSystemAir4],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },
  {
    id: 5,
    title: 'Suspended sliding system Air Soft',
    previewUrl: SuspendedSlidingSystemAirSoft1, // Remplacez par le chemin de votre image
    imageUrls: [SuspendedSlidingSystemAirSoft1, SuspendedSlidingSystemAirSoft2, SuspendedSlidingSystemAirSoft3, SuspendedSlidingSystemAirSoft4],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },
  {
    id: 6,
    title: 'Wardrobes and wardrobe systems WDB_WDBWL',
    previewUrl: WardrobesAndWardrobeSystemsWdb_Wdbwl1, // Remplacez par le chemin de votre image
    imageUrls: [WardrobesAndWardrobeSystemsWdb_Wdbwl1, WardrobesAndWardrobeSystemsWdb_Wdbwl3, WardrobesAndWardrobeSystemsWdb_Wdbwl5, WardrobesAndWardrobeSystemsWdb_Wdbwl7, WardrobesAndWardrobeSystemsWdb_Wdbwl8, WardrobesAndWardrobeSystemsWdb_Wdbwl9, WardrobesAndWardrobeSystemsWdb_Wdbwl11, WardrobesAndWardrobeSystemsWdb_Wdbwl12, WardrobesAndWardrobeSystemsWdb_Wdbwl15, WardrobesAndWardrobeSystemsWdb_Wdbwl16, WardrobesAndWardrobeSystemsWdb_Wdbwl17, WardrobesAndWardrobeSystemsWdb_Wdbwl18, WardrobesAndWardrobeSystemsWdb_Wdbwl20, WardrobesAndWardrobeSystemsWdb_Wdbwl21, WardrobesAndWardrobeSystemsWdb_Wdbwl24, WardrobesAndWardrobeSystemsWdb_Wdbwl27, WardrobesAndWardrobeSystemsWdb_Wdbwl28, WardrobesAndWardrobeSystemsWdb_Wdbwl29, WardrobesAndWardrobeSystemsWdb_Wdbwl31, WardrobesAndWardrobeSystemsWdb_Wdbwl32, WardrobesAndWardrobeSystemsWdb_Wdbwl33, WardrobesAndWardrobeSystemsWdb_Wdbwl34, WardrobesAndWardrobeSystemsWdb_Wdbwl35, WardrobesAndWardrobeSystemsWdb_Wdbwl38, WardrobesAndWardrobeSystemsWdb_Wdbwl41, WardrobesAndWardrobeSystemsWdb_Wdbwl42, WardrobesAndWardrobeSystemsWdb_Wdbwl43, WardrobesAndWardrobeSystemsWdb_Wdbwl44, WardrobesAndWardrobeSystemsWdb_Wdbwl45, WardrobesAndWardrobeSystemsWdb_Wdbwl47, WardrobesAndWardrobeSystemsWdb_Wdbwl48, WardrobesAndWardrobeSystemsWdb_Wdbwl49, WardrobesAndWardrobeSystemsWdb_Wdbwl51, WardrobesAndWardrobeSystemsWdb_Wdbwl53, WardrobesAndWardrobeSystemsWdb_Wdbwl54, WardrobesAndWardrobeSystemsWdb_Wdbwl55
    ],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies'
  },
  {
    id: 7,
    title: 'Wardrobes helving systems',
    previewUrl: WardrobesHelvingSystems1, // Remplacez par le chemin de votre image
    imageUrls: [WardrobesHelvingSystems1, WardrobesHelvingSystems2, WardrobesHelvingSystems3, WardrobesHelvingSystems5, WardrobesHelvingSystems6, WardrobesHelvingSystems7, WardrobesHelvingSystems9, WardrobesHelvingSystems10, WardrobesHelvingSystems11, WardrobesHelvingSystems12],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet'
  }

];
function ProductDisplay() {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };
  return (
    <div className="product-display-container">
      {products.map((product) => (
        <div className="product-card" key={product.id} onClick={() => handleProductClick(product)}>
          <img src={product.previewUrl} alt={product.title} className="product-image" />
          <div className="product-info">
            <h2 className="product-title">{product.title}</h2>
          </div>
        </div>
      ))}
      {isModalOpen && <ProductModal product={selectedProduct} onClose={handleCloseModal} />}

    </div>
  );
}

export default ProductDisplay;
