import './App.scss';
import { Route, Routes } from 'react-router-dom';

import HomePage from '../HomePage/homePage';
import About from '../About/about';
import Menu from '../Menu/menu';
import PrivacyPolicy from '../cookies/privacyPolicy';
import NotFound from '../404/404';
import OurLocation from '../ourLocation/ourLocation';
import Contact from '../contact/contact';

import Footer from '../footer/footer';

// concepts pages
import AllConcepts from '../concepts/allConcepts';
import Kitchens from '../concepts/Kitchens/kitchens';
import Office from '../concepts/Offices/offices';
import LivingRooms from '../concepts/LivingRooms/livingRooms';
import Bedrooms from '../concepts/Bedrooms/bedrooms';
import Bathrooms from '../concepts/Bathrooms/bathrooms';
import InteriorDoors from '../concepts/InteriorDoors/interiorDoors';
import TableAndChairs from '../concepts/TableChairs/tableChairs';
import ClosetAndSlidingSystems from '../concepts/Closet/closet';
import HomeAccessories from '../concepts/HomeAccessories/homeAccessories';
import MaterialsFittingAndAccessories from '../concepts/MaterialFitting/materialFitting';
import InteriorElements from '../concepts/InteriorElements/interiorElement';
import HotelFurniture from '../concepts/HotelFurniture/hotelFurniture';

function App() {
  return (
    <div className="App">
      <Menu />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/about" element={<About />} />
        <Route path="/location" element={<OurLocation />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/concepts" element={<AllConcepts />} />
        <Route path="/kitchens" element={<Kitchens />} />
        <Route path="/office" element={<Office />} />
        <Route path="/livingRooms" element={<LivingRooms />} />
        <Route path="/bedrooms" element={<Bedrooms />} />
        <Route path="/bathrooms" element={<Bathrooms />} />
        <Route path="/interior-doors" element={<InteriorDoors />} />
        <Route path="/table-and-chairs" element={<TableAndChairs />} />
        <Route path="/closet-and-sliding-systems" element={<ClosetAndSlidingSystems />} />
        <Route path="/home-accessories" element={<HomeAccessories />} />
        <Route path="/materials-fitting-and-accessories" element={<MaterialsFittingAndAccessories />} />
        <Route path="/interior-elements" element={<InteriorElements />} />
        <Route path="/hotel-furniture" element={<HotelFurniture />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
