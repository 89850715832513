import { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import './contact.scss';

function ContactForm() {
  const [contact, setContact] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });

  const [phoneCode, setPhoneCode] = useState('+971'); // Indicatif par défaut pour Dubaï

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handlePhoneCodeChange = (e) => {
    setPhoneCode(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://drivesoft-e728p.ondigitalocean.app/zov-mail/send', contact);
      Swal.fire('Success', response.data, 'success');
    }
    catch (error) {
      Swal.fire('Error', 'Something went wrong!', 'error');
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <h1>Contact us</h1>
      <input type="text" name="firstName" placeholder="First Name" value={contact.firstName} onChange={handleChange} />
      <input type="text" name="lastName" placeholder="Last Name" value={contact.lastName} onChange={handleChange} />
      <input type="email" name="email" placeholder="Email" value={contact.email} onChange={handleChange} />
      <div className="phone-input-container">
        <select value={phoneCode} onChange={handlePhoneCodeChange}>
          <option value="+971">+971</option>
          <option value="+974">+974</option>
          <option value="+7">+7</option>
          <option value="+995">+995</option>
          <option value="+375">+375</option>
          <option value="+33">+33</option>
        </select>
        <input type="tel" name="phone" placeholder="Phone Number" value={contact.phone} onChange={handleChange} />
      </div>
      <textarea
        name="message"
        placeholder="Your message"
        value={contact.message}
        onChange={handleChange}
      />
      <button type="submit">Send</button>
    </form>
  );
}

export default ContactForm;
