/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import './menu.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import logo from '../../assets/images/logo/ZovLogoWhite.png';

const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFF',
          color: '#1c2240',
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.25)',
          borderRadius: '8px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#1c2240',
          fontSize: '1.1rem',
          transition: '0.3s',
          '&:hover': {
            backgroundColor: '#1c2240',
            color: 'white',
            textDecoration: 'none',
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#1c2240',
          color: 'white',
          width: '60%',
          padding: '2rem',
        },
      },
    },
  },
});

function MenuComponent() {
  const [anchorElProducts, setAnchorElProducts] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleProductsClick = (event) => {
    setAnchorElProducts(event.currentTarget);
  };

  const handleCloseProducts = () => {
    setAnchorElProducts(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerOpen(open);
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      style={{
        display: 'flex', flexDirection: 'column', textAlign: 'center',
      }}
    >
      <Link to="/">
        <img src={logo} alt="ZOV Furniture LLC" className="logoMobile" />
      </Link>
      <Button
        sx={{
          color: 'white', marginBottom: '4rem', textAlign: 'center', fontSize: '1.2rem'
        }}
        href="/about"
      >
        About us
      </Button>
      <Button
        sx={{
          color: 'white', marginBottom: '4rem', textAlign: 'center', fontSize: '1.2rem'
        }}
        href="/concepts"
      >
        Products
      </Button>
      <Button
        sx={{
          color: 'white', marginBottom: '4rem', textAlign: 'center', fontSize: '1.2rem'
        }}
        href="/contact"
      >
        Contact
      </Button>
      <Button
        sx={{
          color: 'white', marginBottom: '4rem', textAlign: 'center', fontSize: '1.2rem'
        }}
        href="/location"
      >
        Our Showroom
      </Button>
      <footer className="footer">
        <div className="social-media">
          <a href="https://www.instagram.com/zov.furniture.llc/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
          <a href="https://www.tiktok.com/@zov.furniture.llc" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTiktok} size="2x" />
          </a>
          <a href="https://www.linkedin.com/company/zov-furniture-l-l-c/about/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>
        </div>
      </footer>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <header className="header">
        <Link to="/">
          <img src={logo} alt="ZOV Furniture LLC" className="logo" />
        </Link>
        <IconButton
          edge="start"
          aria-label="menu"
          fontSize="large"
          sx={{ mr: 2, display: { sm: 'none', color: 'white' } }}
          onClick={() => setDrawerOpen(!drawerOpen)}
        >
          {drawerOpen ? <CloseIcon fontSize="large" /> : <MenuIcon fontSize="large" />}
        </IconButton>
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
        <nav className="navigation">
          <Button href="/about">About us</Button>
          <Button
            sx={{
              color: 'white',
              marginRight: '2rem',
              textShadow: '0 0 8px rgba(0, 0, 0, 0.9)',
              transition: '0.3s',
              '&:hover': {
                pointer: 'cursor',
              },
            }}
            onMouseOver={handleProductsClick}
          >
            Products
          </Button>
          <Menu
            id="products-menu"
            anchorEl={anchorElProducts}
            keepMounted
            open={Boolean(anchorElProducts)}
            onClose={handleCloseProducts}
            sx={{ cursor: 'pointer' }}
            MenuListProps={{ onMouseLeave: handleCloseProducts }}
          >
            <Link to="/kitchens" className="menu-link">
              <MenuItem onClick={handleCloseProducts}>Kitchens</MenuItem>
            </Link>
            <Link to="/office" className="menu-link">
              <MenuItem onClick={handleCloseProducts}>Office & cabinet furniture</MenuItem>
            </Link>
            <Link to="/hotel-furniture" className="menu-link">
              <MenuItem onClick={handleCloseProducts}>Hotel furniture</MenuItem>
            </Link>
            <Link to="/livingRooms" className="menu-link">
              <MenuItem onClick={handleCloseProducts}>Living Room</MenuItem>
            </Link>
            <Link to="/bedrooms" className="menu-link">
              <MenuItem onClick={handleCloseProducts}>Bedroom</MenuItem>
            </Link>
            <Link to="/bathrooms" className="menu-link">
              <MenuItem onClick={handleCloseProducts}>Bathroom</MenuItem>
            </Link>
            <Link to="/interior-doors" className="menu-link">
              <MenuItem onClick={handleCloseProducts}>Interior doors</MenuItem>
            </Link>
            <Link to="/table-and-chairs" className="menu-link">
              <MenuItem onClick={handleCloseProducts}>Table and chairs</MenuItem>
            </Link>
            <Link to="/closet-and-sliding-systems" className="menu-link">
              <MenuItem onClick={handleCloseProducts}>Closet and sliding systems</MenuItem>
            </Link>
            <Link to="/home-accessories" className="menu-link">
              <MenuItem onClick={handleCloseProducts}>Home accessories</MenuItem>
            </Link>
            <Link to="/materials-fitting-and-accessories" className="menu-link">
              <MenuItem onClick={handleCloseProducts}>Materials, fitting and accessories</MenuItem>
            </Link>
            <Link to="/interior-elements" className="menu-link">
              <MenuItem onClick={handleCloseProducts}>Interior elements</MenuItem>
            </Link>
          </Menu>
          <Button href="/location">Our Showroom</Button>
          <Button href="/contact">Contact</Button>
        </nav>
      </header>
    </ThemeProvider>
  );
}

export default MenuComponent;
