/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/control-has-associated-label */
import './ourLocation.scss';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// import showroomImage from '../../assets/images/showroom/showRoomFront.jpg';
// import showroomImage2 from '../../assets/images/showroom/showRoomKitchen.jpg';
// import showroomImage3 from '../../assets/images/showroom/showRoomWardrobe.jpg';

function LocationPage() {
  return (
    <div className="location-page">
      <h1 className="title">Our Showroom</h1>
      <div className="content">
        <div className="video-container">
          <iframe
            className="showroom-video"
            src="https://www.youtube.com/embed/kIADqNd9W-4?si=LfUhLUqGxTWrPM4V"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>
        {/* <div className="img-container">
          <img src={showroomImage} alt="Showroom" className="showroom-image" />
          <img src={showroomImage2} alt="Showroom" className="showroom-image hideOnMobile" />
          <img src={showroomImage3} alt="Showroom" className="showroom-image hideOnMobile" />
        </div> */}
        <div className="info">
          <div className="info-item">
            <LocationOnIcon className="icon" />
            <div>
              <h2>Address</h2>
              <p>Grand Floor NBH Tower, Al Muhairy Centre – Zayed The First St</p>
              <p>Al Hisn - W4 – Abu Dhabi - UAE</p>
            </div>
          </div>

          <div className="info-item">
            <AccessTimeIcon className="icon" />
            <div>
              <p>10:00AM–8:00PM Everyday</p>
            </div>
          </div>
          <div className="info-item">
            <EmailIcon className="icon" />
            <div>
              <a href="mailto:info@zov.ae" className="contact-link">info@zov.ae</a>
            </div>
          </div>

          <div className="info-item">
            <PhoneIcon className="icon" />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <a href="tel:+971509636346" className="contact-link">+971509636346</a>
              <a href="tel:+971522518853" className="contact-link">+971522518853</a>
            </div>
          </div>
          <p className="invitation">
            We cordially invite you to immerse yourself in a world of luxury at our showroom!
          </p>
        </div>
      </div>
    </div>
  );
}

export default LocationPage;
