/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable max-len */
import React, { useState } from 'react';
import Slider from 'react-slick';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import logoEn from '../../assets/images/about/logo-en.jpg';
import environnement from '../../assets/images/about/environnement.jpg';
import family from '../../assets/images/about/family.jpg';
import network from '../../assets/images/about/network.jpg';
import puzzle from '../../assets/images/about/puzzle.jpg';
import meeting from '../../assets/images/about/meeting.jpg';
import './about.scss';
import 'slick-carousel/slick/slick.css';

const videoData = [
  {
    src: 'https://www.youtube.com/watch?v=YnUeVxktIwY',
    thumb: 'https://zovofficial.com/image/cache/catalog/demo/about/video-513x300.jpg',
    title: 'THE BRAND ZOV',
    description: 'A short presentation video about the company',
    length: '5:28',
  },
  {
    src: 'https://www.youtube.com/watch?v=1ExXQM12dfc',
    thumb: 'https://zovofficial.com/image/cache/catalog/demo/video/1-513x300.jpg',
    title: 'ZOV. INSPIRED BY INNOVATIONS',
    description: '',
    length: '1:50',
  },
  {
    src: 'https://www.youtube.com/watch?v=2K8A_KF6MPU',
    thumb: 'https://zovofficial.com/image/cache/catalog/demo/video/momentyszov-513x300.jpg',
    title: 'MOMENTS WITH ZOV',
    description: '',
    length: '00:30',
  },
  {
    src: 'https://www.youtube.com/watch?v=7uqgD3DVWv0',

    title: 'The most important events and precious moments in 2019',
    thumb: 'https://zovofficial.com/image/cache/catalog/demo/about/videos/2-en-513x300.jpeg',
    description: 'Furniture for life',
    length: '1:34',
  },
  {
    src: 'https://www.youtube.com/watch?v=mJewjRUrsgM',
    thumb: 'https://zovofficial.com/image/cache/catalog/demo/about/videos/3-513x300.jpg',
    title: 'Furniture for life',
    description: 'An image video which demonstrates the liveliest moments happening between a man and a woman',
    length: '3:19',
  },
  {
    src: 'https://www.youtube.com/watch?v=L4-O9KgCtUg',
    thumb: 'https://zovofficial.com/image/cache/catalog/demo/about/videos/4-513x300.jpeg',
    title: 'KITCHEN OVERVIEW',
    description: 'Furniture for life',
    length: '0:35',
  },
  {
    src: 'https://www.youtube.com/watch?v=AwFw-VKOzPA',
    thumb: 'https://zovofficial.com/image/cache/catalog/demo/about/videos/5-513x300.jpeg',
    title: 'Surfaces',
    description: 'Macro presentation of materials and finishes',
    length: '2:59',
  },
];

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow`}
      style={{ ...style, display: 'block', right: '-60px' }}
      onClick={onClick}
    >
      <ArrowForwardIosIcon style={{ fontSize: '50px', color: 'white' }} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow`}
      style={{ ...style, display: 'block', left: '-60px' }}
      onClick={onClick}
    >
      <ArrowBackIosNewIcon style={{ fontSize: '50px', color: 'white' }} />
    </div>
  );
}

function About() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');

  const openModal = (videoUrl) => {
    setCurrentVideoUrl(videoUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentVideoUrl('');
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className="about">
      <div className="about__top">
        <video autoPlay loop muted className="background-video">
          <source src="https://zovbucket.s3.eu-west-3.amazonaws.com/about-en-2.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="container">
        <div className="breadcrumbs">
          <ul itemScope itemType="http://schema.org/BreadcrumbList">
            <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
              <a itemProp="item" href="https://zov.ae/">
                <span itemProp="name">HOMEPAGE</span>
              </a>
              <meta itemProp="position" content="1" />
            </li>
            <li><span>ABOUT ZOV</span></li>
          </ul>
        </div>
      </div>

      <div className="about__block">
        <div className="block block_image_right">
          <div className="row">
            <div className="columns small-12 large-6 large-order-2 block__image" style={{ backgroundImage: `url(${logoEn})` }} />
            <div className="columns small-12 large-6 large-order-1">
              <div className="block__content">
                <h1 className="block__title">ZOV GROUP</h1>
                <div className="block__text output">
                  <p>ZOV GROUP is a family company established in 1997 and managed by its members in the third generation. For more than 25 years we have been a leader in kitchen and other cabinet furniture production in Belarus and abroad. ZOV GROUP possesses over 65 thousand sq. m of production floor space. More than 2 thousand highly qualified employees are involved in production process. All production facilities are equipped with the latest high-technology machines and fully automated lines. We constantly implement new technologies for being able to offer variously designed furniture of any complexity. Regular participation in trade fairs and exhibitions for furniture and industrial equipment as well as professional trainings keep us up to date on current trends in the industry.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="about__holder">
            <div className="about__video player">
              <iframe width="100%" height="529" allow="autoplay" src="https://www.youtube.com/embed/LNk_2Y5xiyA?si=cgCZLBJGiUInV6qF" frameBorder="0" allowFullScreen />
            </div>
          </div>
        </div>
      </div>

      <div className="container slider-container">
        <div className="slider slider_type_videos js-slider slick-initialized slick-slider">
          <Slider {...settings}>
            {videoData.map((video, index) => (
              <div key={index} className="video-item">
                <div className="video-item__image">
                  <img src={video.thumb} alt={video.title} />
                  <button type="button" className="play-button" onClick={() => openModal(video.src)}>▶</button>
                </div>
                <div className="video-item__wrapper">
                  <span className="video-item__length">{video.length}</span>
                  <a href={video.src} className="video-item__title" target="_blank" rel="noopener noreferrer">{video.title}</a>
                  <span className="video-item__description">{video.description}</span>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            height: '90%',
            padding: '0',
            border: 'none',
            borderRadius: '8px',
            overflow: 'hidden',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
        }}
      >
        <ReactPlayer url={currentVideoUrl} width="100%" height="100%" controls />
      </Modal>

      <div className="about__block about__block_type_bottom">
        <div className="block block_image_left">
          <div className="row">
            <div className="columns small-12 large-6 block__image" style={{ backgroundImage: `url(${network})` }} />
            <div className="columns small-12 large-6">
              <div className="block__content">
                <span className="block__title">DEALER NETWORK</span>
                <div className="block__text output">
                  <p>Our developed dealer network has more than 1000 representation offices and self-managed retail stores in more than 25 countries worldwide. We actively support our representatives in their development activities, undertake joint market studies and introduce new products and solutions which are highly commended for outstanding functionality and innovative design.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block block_image_right">
          <div className="row">
            <div className="columns small-12 large-6 large-order-2 block__image" style={{ backgroundImage: `url(${family})` }} />
            <div className="columns small-12 large-6 large-order-1">
              <div className="block__content">
                <span className="block__title">BRAND PHILOSOPHY</span>
                <div className="block__text output">
                  <p>We have acquired a high level of proficiency in producing high-quality and trendy products which are affordable for customers with different income. Our main goal is not merely to sell a product, but to present it in a carefully designed space.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block block_image_left">
          <div className="row">
            <div className="columns small-12 large-6 block__image" style={{ backgroundImage: `url(${puzzle})` }} />
            <div className="columns small-12 large-6">
              <div className="block__content">
                <span className="block__title">KEY SUCCESS FACTORS</span>
                <div className="block__text output">
                  <p>Inspiring ideas, invaluable experience and continuous development are the reason why we succeed when creating a new product. We bring everything together under one roof so that you feel safe and comfortable.</p>
                  <p>Accentuating quality and functionality we make our customers happier.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block block_image_right">
          <div className="row">
            <div className="columns small-12 large-6 large-order-2 block__image" style={{ backgroundImage: `url(${environnement})` }} />
            <div className="columns small-12 large-6 large-order-1">
              <div className="block__content">
                <span className="block__title">CONCERN FOR THE ENVIRONMENT</span>
                <div className="block__text output">
                  <p>Being an environmentally focused company we use only certified raw materials and constantly develop new technologies to ensure maximum energy efficiency. We are convinced that a careful use of energy is directly related to cost-effectiveness and our successful operation.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block block_image_left">
          <div className="row">
            <div className="columns small-12 large-6 block__image" style={{ backgroundImage: `url(${meeting})` }} />
            <div className="columns small-12 large-6">
              <div className="block__content">
                <span className="block__title">MISSION</span>
                <div className="block__text output">
                  <p>Our <strong>mission</strong> is not to produce and sell the furniture itself but to support our customers at the stage of solution finding how to make the space around them more comfortable and functional. We offer non-standard comprehensive interior design ideas by producing <strong>FURNITURE FOR LIFE.</strong> This is our main competitive advantage.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(About);
