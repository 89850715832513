/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import Pagination from '@mui/material/Pagination';

import './kitchens.scss';

// alberto imports
import Alberto1 from '../../../assets/images/concepts/kitchens/alberto/1.jpg';
import Alberto2 from '../../../assets/images/concepts/kitchens/alberto/2.jpg';
import Alberto3 from '../../../assets/images/concepts/kitchens/alberto/3.jpg';
import Alberto4 from '../../../assets/images/concepts/kitchens/alberto/4.jpg';

// amalia imports
import Amalia1 from '../../../assets/images/concepts/kitchens/amalia/1.jpg';
import Amalia2 from '../../../assets/images/concepts/kitchens/amalia/2.jpg';
import Amalia3 from '../../../assets/images/concepts/kitchens/amalia/3.jpg';
import Amalia4 from '../../../assets/images/concepts/kitchens/amalia/4.jpg';

// antico imports
import Antico1 from '../../../assets/images/concepts/kitchens/antico/1.jpg';
import Antico2 from '../../../assets/images/concepts/kitchens/antico/2.jpg';
import Antico3 from '../../../assets/images/concepts/kitchens/antico/3.jpg';

// astoria imports
import Astoria1 from '../../../assets/images/concepts/kitchens/astoria/1.jpg';
import Astoria2 from '../../../assets/images/concepts/kitchens/astoria/2.jpg';
import Astoria3 from '../../../assets/images/concepts/kitchens/astoria/3.jpg';
import Astoria4 from '../../../assets/images/concepts/kitchens/astoria/4.jpg';

// aurora22 imports
import Aurora221 from '../../../assets/images/concepts/kitchens/aurora22/1.jpg';
import Aurora222 from '../../../assets/images/concepts/kitchens/aurora22/2.jpg';
import Aurora223 from '../../../assets/images/concepts/kitchens/aurora22/3.jpg';

// aurora24 imports
import Aurora241 from '../../../assets/images/concepts/kitchens/aurora24/1.jpg';
import Aurora242 from '../../../assets/images/concepts/kitchens/aurora24/2.jpg';
import Aurora243 from '../../../assets/images/concepts/kitchens/aurora24/3.jpg';
import Aurora244 from '../../../assets/images/concepts/kitchens/aurora24/4.jpg';

// avon imports
import Avon1 from '../../../assets/images/concepts/kitchens/avon/1.jpg';
import Avon2 from '../../../assets/images/concepts/kitchens/avon/2.jpg';
import Avon3 from '../../../assets/images/concepts/kitchens/avon/3.jpg';

// avon22 imports
import Avon221 from '../../../assets/images/concepts/kitchens/avon22/1.jpg';
import Avon222 from '../../../assets/images/concepts/kitchens/avon22/2.jpg';
import Avon223 from '../../../assets/images/concepts/kitchens/avon22/3.jpg';

// avon24 imports
import Avon241 from '../../../assets/images/concepts/kitchens/avon24/1.jpg';
import Avon242 from '../../../assets/images/concepts/kitchens/avon24/2.jpg';
import Avon243 from '../../../assets/images/concepts/kitchens/avon24/3.jpg';
import Avon244 from '../../../assets/images/concepts/kitchens/avon24/4.jpg';

// azur imports
import Azur1 from '../../../assets/images/concepts/kitchens/azur/1.jpg';
import Azur2 from '../../../assets/images/concepts/kitchens/azur/2.jpg';
import Azur3 from '../../../assets/images/concepts/kitchens/azur/3.jpg';
import Azur4 from '../../../assets/images/concepts/kitchens/azur/4.jpg';

// bari22 imports
import Bari221 from '../../../assets/images/concepts/kitchens/bari22/1.jpg';
import Bari222 from '../../../assets/images/concepts/kitchens/bari22/2.jpg';
import Bari223 from '../../../assets/images/concepts/kitchens/bari22/3.jpg';

// berkley imports
import Berkley1 from '../../../assets/images/concepts/kitchens/berkley/1.jpg';
import Berkley2 from '../../../assets/images/concepts/kitchens/berkley/2.jpg';
import Berkley3 from '../../../assets/images/concepts/kitchens/berkley/3.jpg';

// berkley 24 imports
import Berkley241 from '../../../assets/images/concepts/kitchens/berkley24/1.jpg';
import Berkley242 from '../../../assets/images/concepts/kitchens/berkley24/2.jpg';
import Berkley243 from '../../../assets/images/concepts/kitchens/berkley24/3.jpg';
import Berkley244 from '../../../assets/images/concepts/kitchens/berkley24/4.jpg';
import Berkley245 from '../../../assets/images/concepts/kitchens/berkley24/5.jpg';

// berlin imports
import Berlin1 from '../../../assets/images/concepts/kitchens/berlin/1.jpg';
import Berlin2 from '../../../assets/images/concepts/kitchens/berlin/2.jpg';
import Berlin3 from '../../../assets/images/concepts/kitchens/berlin/3.jpg';

// bernini imports
import Bernini1 from '../../../assets/images/concepts/kitchens/bernini/1.jpg';
import Bernini2 from '../../../assets/images/concepts/kitchens/bernini/2.jpg';
import Bernini3 from '../../../assets/images/concepts/kitchens/bernini/3.jpg';
import Bernini4 from '../../../assets/images/concepts/kitchens/bernini/4.jpg';

// betti imports
import Betti1 from '../../../assets/images/concepts/kitchens/betti/1.jpg';
import Betti2 from '../../../assets/images/concepts/kitchens/betti/2.jpg';
import Betti3 from '../../../assets/images/concepts/kitchens/betti/3.jpg';

// boston imports
import Boston1 from '../../../assets/images/concepts/kitchens/boston/1.jpg';
import Boston2 from '../../../assets/images/concepts/kitchens/boston/2.jpg';
import Boston3 from '../../../assets/images/concepts/kitchens/boston/3.jpg';

// brion imports
import Brion1 from '../../../assets/images/concepts/kitchens/brion/1.jpg';
import Brion2 from '../../../assets/images/concepts/kitchens/brion/2.jpg';
import Brion3 from '../../../assets/images/concepts/kitchens/brion/3.jpg';
import Brion4 from '../../../assets/images/concepts/kitchens/brion/4.jpg';
import Brion5 from '../../../assets/images/concepts/kitchens/brion/5.jpg';

// brooks imports

import Brooks1 from '../../../assets/images/concepts/kitchens/brooks/1.jpg';
import Brooks2 from '../../../assets/images/concepts/kitchens/brooks/2.jpg';
import Brooks3 from '../../../assets/images/concepts/kitchens/brooks/3.jpg';
import Brooks4 from '../../../assets/images/concepts/kitchens/brooks/4.jpg';

// brugge imports
import Brugge1 from '../../../assets/images/concepts/kitchens/brugge/1.jpg';
import Brugge2 from '../../../assets/images/concepts/kitchens/brugge/2.jpg';
import Brugge3 from '../../../assets/images/concepts/kitchens/brugge/3.jpg';

// brunette imports
import Brunette1 from '../../../assets/images/concepts/kitchens/brunette/1.jpg';
import Brunette2 from '../../../assets/images/concepts/kitchens/brunette/2.jpg';
import Brunette3 from '../../../assets/images/concepts/kitchens/brunette/3.jpg';

// clermont imports
import Clermont1 from '../../../assets/images/concepts/kitchens/clermont/1.jpg';
import Clermont2 from '../../../assets/images/concepts/kitchens/clermont/2.jpg';
import Clermont3 from '../../../assets/images/concepts/kitchens/clermont/3.jpg';
import Clermont4 from '../../../assets/images/concepts/kitchens/clermont/4.jpg';

// clyde22 imports
import Clyde221 from '../../../assets/images/concepts/kitchens/clyde22/1.jpg';
import Clyde222 from '../../../assets/images/concepts/kitchens/clyde22/2.jpg';
import Clyde223 from '../../../assets/images/concepts/kitchens/clyde22/3.jpg';

// daisy imports
import Daisy1 from '../../../assets/images/concepts/kitchens/daisy/1.jpg';
import Daisy2 from '../../../assets/images/concepts/kitchens/daisy/2.jpg';
import Daisy3 from '../../../assets/images/concepts/kitchens/daisy/3.jpg';

// delphi imports
import Delphi1 from '../../../assets/images/concepts/kitchens/delphi/1.jpg';
import Delphi2 from '../../../assets/images/concepts/kitchens/delphi/2.jpg';
import Delphi3 from '../../../assets/images/concepts/kitchens/delphi/3.jpg';

// dublin imports
import Dublin1 from '../../../assets/images/concepts/kitchens/dublin/1.jpg';
import Dublin2 from '../../../assets/images/concepts/kitchens/dublin/2.jpg';
import Dublin3 from '../../../assets/images/concepts/kitchens/dublin/3.jpg';

// dublin24
import Dublin241 from '../../../assets/images/concepts/kitchens/dublin24/1.jpg';
import Dublin242 from '../../../assets/images/concepts/kitchens/dublin24/2.jpg';
import Dublin243 from '../../../assets/images/concepts/kitchens/dublin24/3.jpg';
import Dublin244 from '../../../assets/images/concepts/kitchens/dublin24/4.jpg';

// ebony imports
import Ebony1 from '../../../assets/images/concepts/kitchens/ebony/1.jpg';
import Ebony2 from '../../../assets/images/concepts/kitchens/ebony/2.jpg';
import Ebony3 from '../../../assets/images/concepts/kitchens/ebony/3.jpg';

// enver imports
import Enver1 from '../../../assets/images/concepts/kitchens/enver/1.jpg';
import Enver2 from '../../../assets/images/concepts/kitchens/enver/2.jpg';
import Enver3 from '../../../assets/images/concepts/kitchens/enver/3.jpg';

// ernesta imports

import Ernesta1 from '../../../assets/images/concepts/kitchens/ernesta/1.jpg';
import Ernesta2 from '../../../assets/images/concepts/kitchens/ernesta/2.jpg';
import Ernesta3 from '../../../assets/images/concepts/kitchens/ernesta/3.jpg';
import Ernesta4 from '../../../assets/images/concepts/kitchens/ernesta/4.jpg';

// etna imports

import Etna1 from '../../../assets/images/concepts/kitchens/etna/1.jpg';
import Etna2 from '../../../assets/images/concepts/kitchens/etna/2.jpg';
import Etna3 from '../../../assets/images/concepts/kitchens/etna/3.jpg';

// fabiana imports

import Fabiana1 from '../../../assets/images/concepts/kitchens/fabiana/1.jpg';
import Fabiana2 from '../../../assets/images/concepts/kitchens/fabiana/2.jpg';
import Fabiana3 from '../../../assets/images/concepts/kitchens/fabiana/3.jpg';
import Fabiana4 from '../../../assets/images/concepts/kitchens/fabiana/4.jpg';

// ferno imports

import Ferno1 from '../../../assets/images/concepts/kitchens/ferno/1.jpg';
import Ferno2 from '../../../assets/images/concepts/kitchens/ferno/2.jpg';
import Ferno3 from '../../../assets/images/concepts/kitchens/ferno/3.jpg';
import Ferno4 from '../../../assets/images/concepts/kitchens/ferno/4.jpg';

// flavio22 imports

import Flavio221 from '../../../assets/images/concepts/kitchens/flavio22/1.jpg';
import Flavio222 from '../../../assets/images/concepts/kitchens/flavio22/2.jpg';
import Flavio223 from '../../../assets/images/concepts/kitchens/flavio22/3.jpg';

// flavio24 imports

import Flavio241 from '../../../assets/images/concepts/kitchens/flavio24/1.jpg';
import Flavio242 from '../../../assets/images/concepts/kitchens/flavio24/2.jpg';
import Flavio243 from '../../../assets/images/concepts/kitchens/flavio24/3.jpg';
import Flavio244 from '../../../assets/images/concepts/kitchens/flavio24/4.jpg';

// fleetwood imports

import Fleetwood1 from '../../../assets/images/concepts/kitchens/fleetwood/1.jpg';
import Fleetwood2 from '../../../assets/images/concepts/kitchens/fleetwood/2.jpg';
import Fleetwood3 from '../../../assets/images/concepts/kitchens/fleetwood/3.jpg';

// gloria imports

import Gloria1 from '../../../assets/images/concepts/kitchens/gloria/1.jpg';
import Gloria2 from '../../../assets/images/concepts/kitchens/gloria/2.jpg';
import Gloria3 from '../../../assets/images/concepts/kitchens/gloria/3.jpg';
import Gloria4 from '../../../assets/images/concepts/kitchens/gloria/4.jpg';

// indigo imports

import Indigo1 from '../../../assets/images/concepts/kitchens/indigo/1.jpg';
import Indigo2 from '../../../assets/images/concepts/kitchens/indigo/2.jpg';
import Indigo3 from '../../../assets/images/concepts/kitchens/indigo/3.jpg';

// ines imports

import Ines1 from '../../../assets/images/concepts/kitchens/ines/1.jpg';
import Ines2 from '../../../assets/images/concepts/kitchens/ines/2.jpg';
import Ines3 from '../../../assets/images/concepts/kitchens/ines/3.jpg';
import Ines4 from '../../../assets/images/concepts/kitchens/ines/4.jpg';

// juliet imports

import Juliet1 from '../../../assets/images/concepts/kitchens/juliet/1.jpg';
import Juliet2 from '../../../assets/images/concepts/kitchens/juliet/2.jpg';
import Juliet3 from '../../../assets/images/concepts/kitchens/juliet/3.jpg';
import Juliet4 from '../../../assets/images/concepts/kitchens/juliet/4.jpg';

// kentucky imports

import Kentucky1 from '../../../assets/images/concepts/kitchens/kentucky/1.jpg';
import Kentucky2 from '../../../assets/images/concepts/kitchens/kentucky/2.jpg';
import Kentucky3 from '../../../assets/images/concepts/kitchens/kentucky/3.jpg';

// lamberti imports

import Lamberti1 from '../../../assets/images/concepts/kitchens/lamberti/1.jpg';
import Lamberti2 from '../../../assets/images/concepts/kitchens/lamberti/2.jpg';
import Lamberti3 from '../../../assets/images/concepts/kitchens/lamberti/3.jpg';
import Lamberti4 from '../../../assets/images/concepts/kitchens/lamberti/4.jpg';

// leona imports

import Leona1 from '../../../assets/images/concepts/kitchens/leona/1.jpg';
import Leona2 from '../../../assets/images/concepts/kitchens/leona/2.jpg';
import Leona3 from '../../../assets/images/concepts/kitchens/leona/3.jpg';
import Leona4 from '../../../assets/images/concepts/kitchens/leona/4.jpg';
import Leona5 from '../../../assets/images/concepts/kitchens/leona/5.jpg';

// lia imports

import Lia1 from '../../../assets/images/concepts/kitchens/lia/1.jpg';
import Lia2 from '../../../assets/images/concepts/kitchens/lia/2.jpg';
import Lia3 from '../../../assets/images/concepts/kitchens/lia/3.jpg';
import Lia4 from '../../../assets/images/concepts/kitchens/lia/4.jpg';

// linnea imports

import Linnea1 from '../../../assets/images/concepts/kitchens/linnea/1.jpg';
import Linnea2 from '../../../assets/images/concepts/kitchens/linnea/2.jpg';

// lion imports

import Lion1 from '../../../assets/images/concepts/kitchens/lion/1.jpg';
import Lion2 from '../../../assets/images/concepts/kitchens/lion/2.jpg';
import Lion3 from '../../../assets/images/concepts/kitchens/lion/3.jpg';
import Lion4 from '../../../assets/images/concepts/kitchens/lion/4.jpg';

// livon imports

import Livon1 from '../../../assets/images/concepts/kitchens/livon/1.jpg';
import Livon2 from '../../../assets/images/concepts/kitchens/livon/2.jpg';
import Livon3 from '../../../assets/images/concepts/kitchens/livon/3.jpg';

// livon 22 imports

import Livon221 from '../../../assets/images/concepts/kitchens/livon22/1.jpg';
import Livon222 from '../../../assets/images/concepts/kitchens/livon22/2.jpg';
import Livon223 from '../../../assets/images/concepts/kitchens/livon22/3.jpg';

// livon 24 imports

import Livon241 from '../../../assets/images/concepts/kitchens/livon24/1.jpg';
import Livon242 from '../../../assets/images/concepts/kitchens/livon24/2.jpg';
import Livon243 from '../../../assets/images/concepts/kitchens/livon24/3.jpg';
import Livon244 from '../../../assets/images/concepts/kitchens/livon24/4.jpg';

// london imports

import London1 from '../../../assets/images/concepts/kitchens/london/1.jpg';
import London2 from '../../../assets/images/concepts/kitchens/london/2.jpg';
import London3 from '../../../assets/images/concepts/kitchens/london/3.jpg';
import London4 from '../../../assets/images/concepts/kitchens/london/4.jpg';

// longford imports

import Longford1 from '../../../assets/images/concepts/kitchens/longford/1.jpg';
import Longford2 from '../../../assets/images/concepts/kitchens/longford/2.jpg';
import Longford3 from '../../../assets/images/concepts/kitchens/longford/3.jpg';

// import longford 24

import Longford241 from '../../../assets/images/concepts/kitchens/longford24/1.jpg';
import Longford242 from '../../../assets/images/concepts/kitchens/longford24/2.jpg';
import Longford243 from '../../../assets/images/concepts/kitchens/longford24/3.jpg';

// lugano 24 imports

import Lugano241 from '../../../assets/images/concepts/kitchens/lugano24/1.jpg';
import Lugano242 from '../../../assets/images/concepts/kitchens/lugano24/2.jpg';
import Lugano243 from '../../../assets/images/concepts/kitchens/lugano24/3.jpg';
import Lugano244 from '../../../assets/images/concepts/kitchens/lugano24/4.jpg';

// marmo imports

import Marmo1 from '../../../assets/images/concepts/kitchens/marmo/1.jpg';
import Marmo2 from '../../../assets/images/concepts/kitchens/marmo/2.jpg';
import Marmo3 from '../../../assets/images/concepts/kitchens/marmo/3.jpg';

// memphis imports

import Memphis1 from '../../../assets/images/concepts/kitchens/memphis/1.jpg';
import Memphis2 from '../../../assets/images/concepts/kitchens/memphis/2.jpg';
import Memphis3 from '../../../assets/images/concepts/kitchens/memphis/3.jpg';

// monte imports

import Monte1 from '../../../assets/images/concepts/kitchens/monte/1.jpg';
import Monte2 from '../../../assets/images/concepts/kitchens/monte/2.jpg';
import Monte3 from '../../../assets/images/concepts/kitchens/monte/3.jpg';

// newark22 imports

import Newark221 from '../../../assets/images/concepts/kitchens/newark22/1.jpg';
import Newark222 from '../../../assets/images/concepts/kitchens/newark22/2.jpg';

// nord imports

import Nord1 from '../../../assets/images/concepts/kitchens/nord/1.jpg';
import Nord2 from '../../../assets/images/concepts/kitchens/nord/2.jpg';
import Nord3 from '../../../assets/images/concepts/kitchens/nord/3.jpg';

// nordic imports

import Nordic1 from '../../../assets/images/concepts/kitchens/nordic/1.jpg';
import Nordic2 from '../../../assets/images/concepts/kitchens/nordic/2.jpg';
import Nordic3 from '../../../assets/images/concepts/kitchens/nordic/3.jpg';
import Nordic4 from '../../../assets/images/concepts/kitchens/nordic/4.jpg';
import Nordic5 from '../../../assets/images/concepts/kitchens/nordic/5.jpg';

// orleans22 imports

import Orleans221 from '../../../assets/images/concepts/kitchens/orleans22/1.jpg';
import Orleans222 from '../../../assets/images/concepts/kitchens/orleans22/2.jpg';
import Orleans223 from '../../../assets/images/concepts/kitchens/orleans22/3.jpg';

// piedmont22 imports

import Piedmont221 from '../../../assets/images/concepts/kitchens/piedmont22/1.jpg';
import Piedmont222 from '../../../assets/images/concepts/kitchens/piedmont22/2.jpg';
import Piedmont223 from '../../../assets/images/concepts/kitchens/piedmont22/3.jpg';

// porto imports

import Porto1 from '../../../assets/images/concepts/kitchens/porto/1.jpg';
import Porto2 from '../../../assets/images/concepts/kitchens/porto/2.jpg';

// T308verano imports

import T308verano1 from '../../../assets/images/concepts/kitchens/T308verano/1.jpg';
import T308verano2 from '../../../assets/images/concepts/kitchens/T308verano/2.jpg';
import T308verano3 from '../../../assets/images/concepts/kitchens/T308verano/3.jpg';

// T701tirol imports

import T701tirol1 from '../../../assets/images/concepts/kitchens/T701tirol/1.jpg';
import T701tirol2 from '../../../assets/images/concepts/kitchens/T701tirol/2.jpg';
import T701tirol3 from '../../../assets/images/concepts/kitchens/T701tirol/3.jpg';
import T701tirol4 from '../../../assets/images/concepts/kitchens/T701tirol/4.jpg';

// T706adria imports

import T706adria1 from '../../../assets/images/concepts/kitchens/T706adria/1.jpg';
import T706adria2 from '../../../assets/images/concepts/kitchens/T706adria/2.jpg';
import T706adria3 from '../../../assets/images/concepts/kitchens/T706adria/3.jpg';

// T708daiilia imports

import T708daiilia1 from '../../../assets/images/concepts/kitchens/T708daiilia/1.jpg';
import T708daiilia2 from '../../../assets/images/concepts/kitchens/T708daiilia/2.jpg';
import T708daiilia3 from '../../../assets/images/concepts/kitchens/T708daiilia/3.jpg';

// T709fortwood imports

import T709fortwood1 from '../../../assets/images/concepts/kitchens/T709fortwood/1.jpg';
import T709fortwood2 from '../../../assets/images/concepts/kitchens/T709fortwood/2.jpg';
import T709fortwood3 from '../../../assets/images/concepts/kitchens/T709fortwood/3.jpg';

// T711bellagio imports

import T711bellagio1 from '../../../assets/images/concepts/kitchens/T711bellagio/1.jpg';
import T711bellagio2 from '../../../assets/images/concepts/kitchens/T711bellagio/2.jpg';
import T711bellagio3 from '../../../assets/images/concepts/kitchens/T711bellagio/3.jpg';

// T714vermont imports

import T714vermont1 from '../../../assets/images/concepts/kitchens/T714vermont/1.jpg';
import T714vermont2 from '../../../assets/images/concepts/kitchens/T714vermont/2.jpg';
import T714vermont3 from '../../../assets/images/concepts/kitchens/T714vermont/3.jpg';
import T714vermont4 from '../../../assets/images/concepts/kitchens/T714vermont/4.jpg';

// T715Kartwood imports

import T715Kartwood1 from '../../../assets/images/concepts/kitchens/T715Kartwood/1.jpg';
import T715Kartwood2 from '../../../assets/images/concepts/kitchens/T715Kartwood/2.jpg';
import T715Kartwood3 from '../../../assets/images/concepts/kitchens/T715Kartwood/3.jpg';
import T715Kartwood4 from '../../../assets/images/concepts/kitchens/T715Kartwood/4.jpg';

// T719amato imports

import T719amato1 from '../../../assets/images/concepts/kitchens/T719amato/1.jpg';
import T719amato2 from '../../../assets/images/concepts/kitchens/T719amato/2.jpg';
import T719amato3 from '../../../assets/images/concepts/kitchens/T719amato/3.jpg';
import T719amato4 from '../../../assets/images/concepts/kitchens/T719amato/4.jpg';

// T720severine imports

import T720severine1 from '../../../assets/images/concepts/kitchens/T720severine/1.jpg';
import T720severine2 from '../../../assets/images/concepts/kitchens/T720severine/2.jpg';
import T720severine3 from '../../../assets/images/concepts/kitchens/T720severine/3.jpg';

// T732gainsborougii imports

import T732gainsborougii1 from '../../../assets/images/concepts/kitchens/T732gainsborougii/1.jpg';
import T732gainsborougii2 from '../../../assets/images/concepts/kitchens/T732gainsborougii/2.jpg';
import T732gainsborougii3 from '../../../assets/images/concepts/kitchens/T732gainsborougii/3.jpg';

// T737lugano imports

import T737lugano1 from '../../../assets/images/concepts/kitchens/T737lugano/1.jpg';
import T737lugano2 from '../../../assets/images/concepts/kitchens/T737lugano/2.jpg';
import T737lugano3 from '../../../assets/images/concepts/kitchens/T737lugano/3.jpg';
import T737lugano4 from '../../../assets/images/concepts/kitchens/T737lugano/4.jpg';

// T760piedmont imports

import T760piedmont1 from '../../../assets/images/concepts/kitchens/T760piedmont/1.jpg';
import T760piedmont2 from '../../../assets/images/concepts/kitchens/T760piedmont/2.jpg';
import T760piedmont3 from '../../../assets/images/concepts/kitchens/T760piedmont/3.jpg';
import T760piedmont4 from '../../../assets/images/concepts/kitchens/T760piedmont/4.jpg';
import T760piedmont5 from '../../../assets/images/concepts/kitchens/T760piedmont/5.jpg';
import T760piedmont6 from '../../../assets/images/concepts/kitchens/T760piedmont/6.jpg';

// T761orleans imports

import T761orleans1 from '../../../assets/images/concepts/kitchens/T761orleans/1.jpg';
import T761orleans2 from '../../../assets/images/concepts/kitchens/T761orleans/2.jpg';
import T761orleans3 from '../../../assets/images/concepts/kitchens/T761orleans/3.jpg';
import T761orleans4 from '../../../assets/images/concepts/kitchens/T761orleans/4.jpg';
import T761orleans5 from '../../../assets/images/concepts/kitchens/T761orleans/5.jpg';

// T762vescona imports

import T762vescona1 from '../../../assets/images/concepts/kitchens/T762vescona/1.jpg';
import T762vescona2 from '../../../assets/images/concepts/kitchens/T762vescona/2.jpg';
import T762vescona3 from '../../../assets/images/concepts/kitchens/T762vescona/3.jpg';
import T762vescona4 from '../../../assets/images/concepts/kitchens/T762vescona/4.jpg';

// tigerwood imports

import Tigerwood1 from '../../../assets/images/concepts/kitchens/tigerwood/1.jpg';
import Tigerwood2 from '../../../assets/images/concepts/kitchens/tigerwood/2.jpg';
import Tigerwood3 from '../../../assets/images/concepts/kitchens/tigerwood/3.jpg';

// trent imports

import Trent1 from '../../../assets/images/concepts/kitchens/trent/1.jpg';
import Trent2 from '../../../assets/images/concepts/kitchens/trent/2.jpg';
import Trent3 from '../../../assets/images/concepts/kitchens/trent/3.jpg';

// trent 22 imports

import Trent221 from '../../../assets/images/concepts/kitchens/trent22/1.jpg';
import Trent222 from '../../../assets/images/concepts/kitchens/trent22/2.jpg';
import Trent223 from '../../../assets/images/concepts/kitchens/trent22/3.jpg';

// trent 24 imports

import Trent241 from '../../../assets/images/concepts/kitchens/trent24/1.jpg';
import Trent242 from '../../../assets/images/concepts/kitchens/trent24/2.jpg';
import Trent243 from '../../../assets/images/concepts/kitchens/trent24/3.jpg';
import Trent244 from '../../../assets/images/concepts/kitchens/trent24/4.jpg';

// urban imports

import Urban1 from '../../../assets/images/concepts/kitchens/urban/1.jpg';
import Urban2 from '../../../assets/images/concepts/kitchens/urban/2.jpg';
import Urban3 from '../../../assets/images/concepts/kitchens/urban/3.jpg';

// valetta imports

import Valetta1 from '../../../assets/images/concepts/kitchens/valetta/1.jpg';
import Valetta2 from '../../../assets/images/concepts/kitchens/valetta/2.jpg';
import Valetta3 from '../../../assets/images/concepts/kitchens/valetta/3.jpg';

// velvet imports

import Velvet1 from '../../../assets/images/concepts/kitchens/velvet/1.jpg';
import Velvet2 from '../../../assets/images/concepts/kitchens/velvet/2.jpg';
import Velvet3 from '../../../assets/images/concepts/kitchens/velvet/3.jpg';
import Velvet4 from '../../../assets/images/concepts/kitchens/velvet/4.jpg';

// venato imports

import Venato1 from '../../../assets/images/concepts/kitchens/venato/1.jpg';
import Venato2 from '../../../assets/images/concepts/kitchens/venato/2.jpg';
import Venato3 from '../../../assets/images/concepts/kitchens/venato/3.jpg';
import Venato4 from '../../../assets/images/concepts/kitchens/venato/4.jpg';
import Venato5 from '../../../assets/images/concepts/kitchens/venato/5.jpg';

// vescona22 imports

import Vescona221 from '../../../assets/images/concepts/kitchens/vescona22/1.jpg';
import Vescona222 from '../../../assets/images/concepts/kitchens/vescona22/2.jpg';
import Vescona223 from '../../../assets/images/concepts/kitchens/vescona22/3.jpg';

import ProductModal from '../productModal/productModal';

const products = [
  {
    id: 1,
    title: 'Alberto',
    previewUrl: Alberto1, // Remplacez par le chemin de votre image
    imageUrls: [Alberto1, Alberto2, Alberto3, Alberto4], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 2,
    title: 'Amalia',
    previewUrl: Amalia1, // Remplacez par le chemin de votre image
    imageUrls: [Amalia1, Amalia2, Amalia3, Amalia4], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 3,
    title: 'Antico',
    previewUrl: Antico1, // Remplacez par le chemin de votre image
    imageUrls: [Antico1, Antico2, Antico3], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 4,
    title: 'Astoria',
    previewUrl: Astoria1, // Remplacez par le chemin de votre image
    imageUrls: [Astoria1, Astoria2, Astoria3, Astoria4], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 5,
    title: 'Aurora 22',
    previewUrl: Aurora221, // Remplacez par le chemin de votre image
    imageUrls: [Aurora221, Aurora222, Aurora223], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 6,
    title: 'Aurora 24',
    previewUrl: Aurora241, // Remplacez par le chemin de votre image
    imageUrls: [Aurora241, Aurora242, Aurora243, Aurora244], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 7,
    title: 'Avon',
    previewUrl: Avon1, // Remplacez par le chemin de votre image
    imageUrls: [Avon1, Avon2, Avon3], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 8,
    title: 'Avon 22',
    previewUrl: Avon221, // Remplacez par le chemin de votre image
    imageUrls: [Avon221, Avon222, Avon223], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 9,
    title: 'Avon 24',
    previewUrl: Avon241, // Remplacez par le chemin de votre image
    imageUrls: [Avon241, Avon242, Avon243, Avon244], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 10,
    title: 'Azur',
    previewUrl: Azur1, // Remplacez par le chemin de votre image
    imageUrls: [Azur1, Azur2, Azur3, Azur4], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 11,
    title: 'Bari 22',
    previewUrl: Bari221, // Remplacez par le chemin de votre image
    imageUrls: [Bari221, Bari222, Bari223], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 12,
    title: 'Berkley',
    previewUrl: Berkley1, // Remplacez par le chemin de votre image
    imageUrls: [Berkley1, Berkley2, Berkley3], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 13,
    title: 'Berkley 24',
    previewUrl: Berkley241, // Remplacez par le chemin de votre image
    imageUrls: [Berkley241, Berkley242, Berkley243, Berkley244, Berkley245], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 14, title: 'Berlin', previewUrl: Berlin1, imageUrls: [Berlin1, Berlin2, Berlin3], description: 'Cuisine Alberto'
  },

  {
    id: 15,
    title: 'Bernini',
    previewUrl: Bernini1, // Remplacez par le chemin de votre image
    imageUrls: [Bernini1, Bernini2, Bernini3, Bernini4], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 16, title: 'Betti', previewUrl: Betti1, imageUrls: [Betti1, Betti2, Betti3], description: 'Cuisine Alberto'
  },
  {
    id: 17, title: 'Boston', previewUrl: Boston1, imageUrls: [Boston1, Boston2, Boston3], description: 'Cuisine Alberto'
  },
  {
    id: 18,
    title: 'Brion',
    previewUrl: Brion1, // Remplacez par le chemin de votre image
    imageUrls: [Brion1, Brion2, Brion3, Brion4, Brion5], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 19,
    title: 'Brooks',
    previewUrl: Brooks1, // Remplacez par le chemin de votre image
    imageUrls: [Brooks1, Brooks2, Brooks3, Brooks4], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 20, title: 'Brugge', previewUrl: Brugge1, imageUrls: [Brugge1, Brugge2, Brugge3], description: 'Cuisine Alberto'
  },
  {
    id: 21,
    title: 'Brunette',
    previewUrl: Brunette1, // Remplacez par le chemin de votre image
    imageUrls: [Brunette1, Brunette2, Brunette3], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 22,
    title: 'Clermont',
    previewUrl: Clermont1, // Remplacez par le chemin de votre image
    imageUrls: [Clermont1, Clermont2, Clermont3, Clermont4], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 23,
    title: 'Clyde 22',
    previewUrl: Clyde221, // Remplacez par le chemin de votre image
    imageUrls: [Clyde221, Clyde222, Clyde223], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 24, title: 'Daisy', previewUrl: Daisy1, imageUrls: [Daisy1, Daisy2, Daisy3], description: 'Cuisine Alberto'
  },
  {
    id: 25,
    title: 'Delphi',
    previewUrl: Delphi1, // Remplacez par le chemin de votre image
    imageUrls: [Delphi1, Delphi2, Delphi3], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 26, title: 'Dublin', previewUrl: Dublin1, imageUrls: [Dublin1, Dublin2, Dublin3], description: 'Cuisine Alberto'
  },
  {
    id: 27,
    title: 'Dublin 24',
    previewUrl: Dublin241, // Remplacez par le chemin de votre image
    imageUrls: [Dublin241, Dublin242, Dublin243, Dublin244], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 28, title: 'Ebony', previewUrl: Ebony1, imageUrls: [Ebony1, Ebony2, Ebony3], description: 'Cuisine Alberto'
  },
  {
    id: 29, title: 'Enver', previewUrl: Enver1, imageUrls: [Enver1, Enver2, Enver3], description: 'Cuisine Alberto'
  },
  {
    id: 30,
    title: 'Ernesta',
    previewUrl: Ernesta1, // Remplacez par le chemin de votre image
    imageUrls: [Ernesta1, Ernesta2, Ernesta3, Ernesta4], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 31, title: 'Etna', previewUrl: Etna1, imageUrls: [Etna1, Etna2, Etna3], description: 'Cuisine Alberto'
  },
  {
    id: 32, title: 'Fabiana', previewUrl: Fabiana1, imageUrls: [Fabiana1, Fabiana2, Fabiana3, Fabiana4], description: 'Cuisine Alberto'
  },

  {
    id: 33,
    title: 'Ferno',
    previewUrl: Ferno1, // Remplacez par le chemin de votre image
    imageUrls: [Ferno1, Ferno2, Ferno3, Ferno4], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 34,
    title: 'Flavio 22',
    previewUrl: Flavio221, // Remplacez par le chemin de votre image
    imageUrls: [Flavio221, Flavio222, Flavio223], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 35,
    title: 'Flavio 24',
    previewUrl: Flavio241, // Remplacez par le chemin de votre image
    imageUrls: [Flavio241, Flavio242, Flavio243, Flavio244], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 36,
    title: 'Fleetwood',
    previewUrl: Fleetwood1, // Remplacez par le chemin de votre image
    imageUrls: [Fleetwood1, Fleetwood2, Fleetwood3], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 37,
    title: 'Gloria',
    previewUrl: Gloria1, // Remplacez par le chemin de votre image
    imageUrls: [Gloria1, Gloria2, Gloria3, Gloria4], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 38, title: 'Indigo', previewUrl: Indigo1, imageUrls: [Indigo1, Indigo2, Indigo3], description: 'Cuisine Alberto'
  },
  {
    id: 39,
    title: 'Ines',
    previewUrl: Ines1, // Remplacez par le chemin de votre image
    imageUrls: [Ines1, Ines2, Ines3, Ines4], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 40,
    title: 'Juliet',
    previewUrl: Juliet1, // Remplacez par le chemin de votre image
    imageUrls: [Juliet1, Juliet2, Juliet3, Juliet4], // Un tableau d'images
    description: 'Cuisine Alberto',
  },

  {
    id: 41,
    title: 'Kentucky',
    previewUrl: Kentucky1, // Remplacez par le chemin de votre image
    imageUrls: [Kentucky1, Kentucky2, Kentucky3], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 42,
    title: 'Lamberti',
    previewUrl: Lamberti1, // Remplacez par le chemin de votre image
    imageUrls: [Lamberti1, Lamberti2, Lamberti3, Lamberti4], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 43,
    title: 'Leona',
    previewUrl: Leona1, // Remplacez par le chemin de votre image
    imageUrls: [Leona1, Leona2, Leona3, Leona4, Leona5], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 44, title: 'Lia', previewUrl: Lia1, imageUrls: [Lia1, Lia2, Lia3, Lia4], description: 'Cuisine Alberto'
  },
  {
    id: 45,
    title: 'Linnea',
    previewUrl: Linnea1, // Remplacez par le chemin de votre image
    imageUrls: [Linnea1, Linnea2], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 46, title: 'Lion', previewUrl: Lion1, imageUrls: [Lion1, Lion2, Lion3, Lion4], description: 'Cuisine Alberto'
  },
  {
    id: 47, title: 'Livon', previewUrl: Livon1, imageUrls: [Livon1, Livon2, Livon3], description: 'Cuisine Alberto'
  },
  {
    id: 48,
    title: 'Livon 22',
    previewUrl: Livon221, // Remplacez par le chemin de votre image
    imageUrls: [Livon221, Livon222, Livon223], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 49,
    title: 'Livon 24',
    previewUrl: Livon241, // Remplacez par le chemin de votre image
    imageUrls: [Livon241, Livon242, Livon243, Livon244], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 50,
    title: 'London',
    previewUrl: London1, // Remplacez par le chemin de votre image
    imageUrls: [London1, London2, London3, London4], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 51,
    title: 'Longford',
    previewUrl: Longford1, // Remplacez par le chemin de votre image
    imageUrls: [Longford1, Longford2, Longford3], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 52,
    title: 'Longford 24',
    previewUrl: Longford241, // Remplacez par le chemin de votre image
    imageUrls: [Longford241, Longford242, Longford243], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 53,
    title: 'Lugano 24',
    previewUrl: Lugano241, // Remplacez par le chemin de votre image
    imageUrls: [Lugano241, Lugano242, Lugano243, Lugano244], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 54, title: 'Marmo', previewUrl: Marmo1, imageUrls: [Marmo1, Marmo2, Marmo3], description: 'Cuisine Alberto'
  },
  {
    id: 55,
    title: 'Memphis',
    previewUrl: Memphis1, // Remplacez par le chemin de votre image
    imageUrls: [Memphis1, Memphis2, Memphis3], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 56, title: 'Monte', previewUrl: Monte1, imageUrls: [Monte1, Monte2, Monte3], description: 'Cuisine Alberto'
  },
  {
    id: 57,
    title: 'Newark 22',
    previewUrl: Newark221, // Remplacez par le chemin de votre image
    imageUrls: [Newark221, Newark222], // Un tableau d'images
    description: 'Cuisine Alberto',
  },

  {
    id: 58,
    title: 'Nord',
    previewUrl: Nord1, // Remplacez par le chemin de votre image
    imageUrls: [Nord1, Nord2, Nord3], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 59,
    title: 'Nordic',
    previewUrl: Nordic1, // Remplacez par le chemin de votre image
    imageUrls: [Nordic1, Nordic2, Nordic3, Nordic4, Nordic5], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 60,
    title: 'Orleans 22',
    previewUrl: Orleans221, // Remplacez par le chemin de votre image
    imageUrls: [Orleans221, Orleans222, Orleans223], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 61,
    title: 'Piedmont 22',
    previewUrl: Piedmont221, // Remplacez par le chemin de votre image
    imageUrls: [Piedmont221, Piedmont222, Piedmont223], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 62,
    title: 'Porto',
    previewUrl: Porto1, // Remplacez par le chemin de votre image
    imageUrls: [Porto1, Porto2], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 63,
    title: 'T308 Verano',
    previewUrl: T308verano1, // Remplacez par le chemin de votre image
    imageUrls: [T308verano1, T308verano2, T308verano3], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 64,
    title: 'T701 Tirol',
    previewUrl: T701tirol1, // Remplacez par le chemin de votre image
    imageUrls: [T701tirol1, T701tirol2, T701tirol3, T701tirol4], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 65,
    title: 'T706 Adria',
    previewUrl: T706adria1, // Remplacez par le chemin de votre image
    imageUrls: [T706adria1, T706adria2, T706adria3], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 66,
    title: 'T708 Daiilia',
    previewUrl: T708daiilia1, // Remplacez par le chemin de votre image
    imageUrls: [T708daiilia1, T708daiilia2, T708daiilia3], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 67,
    title: 'T709 Fortwood',
    previewUrl: T709fortwood1, // Remplacez par le chemin de votre image
    imageUrls: [T709fortwood1, T709fortwood2, T709fortwood3], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 68,
    title: 'T711 Bellagio',
    previewUrl: T711bellagio1, // Remplacez par le chemin de votre image
    imageUrls: [T711bellagio1, T711bellagio2, T711bellagio3], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 69,
    title: 'T714 Vermont',
    previewUrl: T714vermont1, // Remplacez par le chemin de votre image
    imageUrls: [T714vermont1, T714vermont2, T714vermont3, T714vermont4], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 70,
    title: 'T715 Kartwood',
    previewUrl: T715Kartwood1, // Remplacez par le chemin de votre image
    imageUrls: [T715Kartwood1, T715Kartwood2, T715Kartwood3, T715Kartwood4], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 71,
    title: 'T719 Amato',
    previewUrl: T719amato1, // Remplacez par le chemin de votre image
    imageUrls: [T719amato1, T719amato2, T719amato3, T719amato4], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 72,
    title: 'T720 Severine',
    previewUrl: T720severine1, // Remplacez par le chemin de votre image
    imageUrls: [T720severine1, T720severine2, T720severine3], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 73,
    title: 'T732 Gainsboroug II',
    previewUrl: T732gainsborougii1, // Remplacez par le chemin de votre image
    imageUrls: [T732gainsborougii1, T732gainsborougii2, T732gainsborougii3], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 74,
    title: 'T737 Lugano',
    previewUrl: T737lugano1, // Remplacez par le chemin de votre image
    imageUrls: [T737lugano1, T737lugano2, T737lugano3, T737lugano4], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 75,
    title: 'T760 Piedmont',
    previewUrl: T760piedmont1, // Remplacez par le chemin de votre image
    imageUrls: [T760piedmont1, T760piedmont2, T760piedmont3, T760piedmont4, T760piedmont5, T760piedmont6], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 76,
    title: 'T761 Orleans',
    previewUrl: T761orleans1, // Remplacez par le chemin de votre image
    imageUrls: [T761orleans1, T761orleans2, T761orleans3, T761orleans4, T761orleans5], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 77,
    title: 'T762 Vescona',
    previewUrl: T762vescona1, // Remplacez par le chemin de votre image
    imageUrls: [T762vescona1, T762vescona2, T762vescona3, T762vescona4], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 78,
    title: 'Tigerwood',
    previewUrl: Tigerwood1, // Remplacez par le chemin de votre image
    imageUrls: [Tigerwood1, Tigerwood2, Tigerwood3], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 79, title: 'Trent', previewUrl: Trent1, imageUrls: [Trent1, Trent2, Trent3], description: 'Cuisine Alberto'
  },
  {
    id: 80,
    title: 'Trent 22',
    previewUrl: Trent221, // Remplacez par le chemin de votre image
    imageUrls: [Trent221, Trent222, Trent223], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 81,
    title: 'Trent 24',
    previewUrl: Trent241, // Remplacez par le chemin de votre image
    imageUrls: [Trent241, Trent242, Trent243, Trent244], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 82, title: 'Urban', previewUrl: Urban1, imageUrls: [Urban1, Urban2, Urban3], description: 'Cuisine Alberto'
  },
  {
    id: 83, title: 'Valetta', previewUrl: Valetta1, imageUrls: [Valetta1, Valetta2, Valetta3], description: 'Cuisine Alberto'
  },
  {
    id: 84,
    title: 'Velvet',
    previewUrl: Velvet1, // Remplacez par le chemin de votre image
    imageUrls: [Velvet1, Velvet2, Velvet3, Velvet4], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 85,
    title: 'Venato',
    previewUrl: Venato1, // Remplacez par le chemin de votre image
    imageUrls: [Venato1, Venato2, Venato3, Venato4, Venato5], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
  {
    id: 86,
    title: 'Vescona 22',
    previewUrl: Vescona221, // Remplacez par le chemin de votre image
    imageUrls: [Vescona221, Vescona222, Vescona223], // Un tableau d'images
    description: 'Cuisine Alberto',
  },
];

function ProductDisplay() {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 12;
  const totalPages = Math.ceil(products.length / productsPerPage);

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };
  return (
    <div className="product-display-container">
      {currentProducts.map((product) => (
        <div className="product-card" key={product.id} onClick={() => handleProductClick(product)}>
          <img src={product.previewUrl} alt={product.title} className="product-image" />
          <div className="product-info">
            <h2 className="product-title">{product.title}</h2>
          </div>
        </div>
      ))}
      {isModalOpen && <ProductModal product={selectedProduct} onClose={handleCloseModal} />}
      <div className="pagination-container">
        <Pagination count={totalPages} page={currentPage} onChange={handleChangePage} />
      </div>

    </div>
  );
}

export default ProductDisplay;
