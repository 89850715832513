/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosNewIcon from '@mui/icons-material/ArrowForwardIos';

function ProductModal({ product, onClose }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(null);

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % product.imageUrls.length);
  };

  const goToPreviousImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + product.imageUrls.length) % product.imageUrls.length);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
      else if (event.key === 'ArrowRight') {
        goToNextImage();
      }
      else if (event.key === 'ArrowLeft') {
        goToPreviousImage();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose, goToNextImage, goToPreviousImage]);

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchStart(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchEnd = e.changedTouches[0].clientX;
    if (touchStart - touchEnd > 75) {
      goToNextImage();
    }
    else if (touchStart - touchEnd < -75) {
      goToPreviousImage();
    }
  };

  // Gestionnaire pour cliquer à l'extérieur de la modale
  const handleClickOutside = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const arrowStyles = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    color: 'white',
    cursor: 'pointer',
    fontSize: '4rem',
    zIndex: 2,
  };

  if (!product || !product.imageUrls) return null;

  return (
    <div className="modal" onClick={handleClickOutside}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()} onTouchStart={handleTouchStart} onTouchEnd={handleTouchMove}>
        <img src={product.imageUrls[currentImageIndex]} alt={product.title} className="modal-image" />
        <ArrowBackIosNewIcon style={{ ...arrowStyles, left: '10px' }} onClick={goToPreviousImage} />
        <ArrowForwardIosNewIcon style={{ ...arrowStyles, right: '10px' }} onClick={goToNextImage} />
        <h2 className="modal-title">{product.title}</h2>
      </div>
    </div>
  );
}

export default ProductModal;
