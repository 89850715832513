/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react';

import '../Kitchens/kitchens.scss';

import ProductModal from '../productModal/productModal';

// accessories import

import Accessories1 from '../../../assets/images/concepts/Materials/accessories/1.jpg';
import Accessories2 from '../../../assets/images/concepts/Materials/accessories/2.jpg';
import Accessories3 from '../../../assets/images/concepts/Materials/accessories/3.jpg';
import Accessories4 from '../../../assets/images/concepts/Materials/accessories/4.jpg';
import Accessories5 from '../../../assets/images/concepts/Materials/accessories/5.jpg';
import Accessories6 from '../../../assets/images/concepts/Materials/accessories/6.jpg';
import Accessories7 from '../../../assets/images/concepts/Materials/accessories/7.jpg';
import Accessories8 from '../../../assets/images/concepts/Materials/accessories/8.jpg';
import Accessories9 from '../../../assets/images/concepts/Materials/accessories/9.jpg';
import Accessories10 from '../../../assets/images/concepts/Materials/accessories/10.jpg';
import Accessories11 from '../../../assets/images/concepts/Materials/accessories/11.jpg';
import Accessories12 from '../../../assets/images/concepts/Materials/accessories/12.jpg';
import Accessories13 from '../../../assets/images/concepts/Materials/accessories/13.jpg';

// cabinetboxesandaccessories import

import Cabinetboxesandaccessories1 from '../../../assets/images/concepts/Materials/cabinetboxesandaccessories/1.jpg';
import Cabinetboxesandaccessories2 from '../../../assets/images/concepts/Materials/cabinetboxesandaccessories/2.jpg';
import Cabinetboxesandaccessories3 from '../../../assets/images/concepts/Materials/cabinetboxesandaccessories/3.jpg';

// materialsforfronts import

import Materialsforfronts1 from '../../../assets/images/concepts/Materials/materialsforfronts/1.jpg';
import Materialsforfronts2 from '../../../assets/images/concepts/Materials/materialsforfronts/2.jpg';
import Materialsforfronts3 from '../../../assets/images/concepts/Materials/materialsforfronts/3.jpg';
import Materialsforfronts4 from '../../../assets/images/concepts/Materials/materialsforfronts/4.jpg';
import Materialsforfronts5 from '../../../assets/images/concepts/Materials/materialsforfronts/5.jpg';
import Materialsforfronts6 from '../../../assets/images/concepts/Materials/materialsforfronts/6.jpg';
import Materialsforfronts7 from '../../../assets/images/concepts/Materials/materialsforfronts/7.jpg';
import Materialsforfronts8 from '../../../assets/images/concepts/Materials/materialsforfronts/8.jpg';
import Materialsforfronts9 from '../../../assets/images/concepts/Materials/materialsforfronts/9.jpg';
import Materialsforfronts10 from '../../../assets/images/concepts/Materials/materialsforfronts/10.jpg';
import Materialsforfronts11 from '../../../assets/images/concepts/Materials/materialsforfronts/11.jpg';
import Materialsforfronts12 from '../../../assets/images/concepts/Materials/materialsforfronts/12.jpg';
import Materialsforfronts13 from '../../../assets/images/concepts/Materials/materialsforfronts/13.jpg';
import Materialsforfronts14 from '../../../assets/images/concepts/Materials/materialsforfronts/14.jpg';
import Materialsforfronts15 from '../../../assets/images/concepts/Materials/materialsforfronts/15.jpg';
import Materialsforfronts16 from '../../../assets/images/concepts/Materials/materialsforfronts/16.jpg';
import Materialsforfronts17 from '../../../assets/images/concepts/Materials/materialsforfronts/17.jpg';
import Materialsforfronts18 from '../../../assets/images/concepts/Materials/materialsforfronts/18.jpg';
import Materialsforfronts19 from '../../../assets/images/concepts/Materials/materialsforfronts/19.jpg';
import Materialsforfronts20 from '../../../assets/images/concepts/Materials/materialsforfronts/20.jpg';
import Materialsforfronts21 from '../../../assets/images/concepts/Materials/materialsforfronts/21.jpg';
import Materialsforfronts22 from '../../../assets/images/concepts/Materials/materialsforfronts/22.jpg';
import Materialsforfronts23 from '../../../assets/images/concepts/Materials/materialsforfronts/23.jpg';
import Materialsforfronts24 from '../../../assets/images/concepts/Materials/materialsforfronts/24.jpg';

// worktopsandaccessories import

import Worktopsandaccessories2 from '../../../assets/images/concepts/Materials/worktopsandaccessories/2.jpg';
import Worktopsandaccessories4 from '../../../assets/images/concepts/Materials/worktopsandaccessories/4.jpg';
import Worktopsandaccessories5 from '../../../assets/images/concepts/Materials/worktopsandaccessories/5.jpg';
import Worktopsandaccessories6 from '../../../assets/images/concepts/Materials/worktopsandaccessories/6.jpg';
import Worktopsandaccessories7 from '../../../assets/images/concepts/Materials/worktopsandaccessories/7.jpg';
import Worktopsandaccessories8 from '../../../assets/images/concepts/Materials/worktopsandaccessories/8.jpg';
import Worktopsandaccessories9 from '../../../assets/images/concepts/Materials/worktopsandaccessories/9.jpg';
import Worktopsandaccessories11 from '../../../assets/images/concepts/Materials/worktopsandaccessories/11.jpg';
import Worktopsandaccessories12 from '../../../assets/images/concepts/Materials/worktopsandaccessories/12.jpg';
import Worktopsandaccessories14 from '../../../assets/images/concepts/Materials/worktopsandaccessories/14.jpg';

// concealedpullgrips import

import Concealedpullgrips1 from '../../../assets/images/concepts/Materials/concealedpullgrips/1.jpg';
import Concealedpullgrips2 from '../../../assets/images/concepts/Materials/concealedpullgrips/2.jpg';
import Concealedpullgrips3 from '../../../assets/images/concepts/Materials/concealedpullgrips/3.jpg';
import Concealedpullgrips4 from '../../../assets/images/concepts/Materials/concealedpullgrips/4.jpg';
import Concealedpullgrips5 from '../../../assets/images/concepts/Materials/concealedpullgrips/5.jpg';
import Concealedpullgrips6 from '../../../assets/images/concepts/Materials/concealedpullgrips/6.jpg';

// drawersystemsandinternalorganizations import

import Drawersystemsandinternalorganizations1 from '../../../assets/images/concepts/Materials/drawersystemsandinternalorganizations/1.jpg';
import Drawersystemsandinternalorganizations2 from '../../../assets/images/concepts/Materials/drawersystemsandinternalorganizations/2.jpg';
import Drawersystemsandinternalorganizations3 from '../../../assets/images/concepts/Materials/drawersystemsandinternalorganizations/3.jpg';
import Drawersystemsandinternalorganizations4 from '../../../assets/images/concepts/Materials/drawersystemsandinternalorganizations/4.jpg';
import Drawersystemsandinternalorganizations6 from '../../../assets/images/concepts/Materials/drawersystemsandinternalorganizations/6.jpg';
import Drawersystemsandinternalorganizations7 from '../../../assets/images/concepts/Materials/drawersystemsandinternalorganizations/7.jpg';
import Drawersystemsandinternalorganizations8 from '../../../assets/images/concepts/Materials/drawersystemsandinternalorganizations/8.jpg';
import Drawersystemsandinternalorganizations9 from '../../../assets/images/concepts/Materials/drawersystemsandinternalorganizations/9.jpg';
import Drawersystemsandinternalorganizations10 from '../../../assets/images/concepts/Materials/drawersystemsandinternalorganizations/10.jpg';
import Drawersystemsandinternalorganizations11 from '../../../assets/images/concepts/Materials/drawersystemsandinternalorganizations/11.jpg';
import Drawersystemsandinternalorganizations12 from '../../../assets/images/concepts/Materials/drawersystemsandinternalorganizations/12.jpg';
import Drawersystemsandinternalorganizations13 from '../../../assets/images/concepts/Materials/drawersystemsandinternalorganizations/13.jpg';
import Drawersystemsandinternalorganizations14 from '../../../assets/images/concepts/Materials/drawersystemsandinternalorganizations/14.jpg';
import Drawersystemsandinternalorganizations15 from '../../../assets/images/concepts/Materials/drawersystemsandinternalorganizations/15.jpg';
import Drawersystemsandinternalorganizations16 from '../../../assets/images/concepts/Materials/drawersystemsandinternalorganizations/16.jpg';
import Drawersystemsandinternalorganizations17 from '../../../assets/images/concepts/Materials/drawersystemsandinternalorganizations/17.jpg';
import Drawersystemsandinternalorganizations18 from '../../../assets/images/concepts/Materials/drawersystemsandinternalorganizations/18.jpg';
import Drawersystemsandinternalorganizations19 from '../../../assets/images/concepts/Materials/drawersystemsandinternalorganizations/19.jpg';
import Drawersystemsandinternalorganizations20 from '../../../assets/images/concepts/Materials/drawersystemsandinternalorganizations/20.jpg';

// edgepulls import

import Edgepulls1 from '../../../assets/images/concepts/Materials/edgepulls/1.jpg';
import Edgepulls2 from '../../../assets/images/concepts/Materials/edgepulls/2.jpg';
import Edgepulls3 from '../../../assets/images/concepts/Materials/edgepulls/3.jpg';
import Edgepulls4 from '../../../assets/images/concepts/Materials/edgepulls/4.jpg';
import Edgepulls5 from '../../../assets/images/concepts/Materials/edgepulls/5.jpg';

// handels import

import Handels1 from '../../../assets/images/concepts/Materials/handels/1.jpg';
import Handels2 from '../../../assets/images/concepts/Materials/handels/2.jpg';
import Handels3 from '../../../assets/images/concepts/Materials/handels/3.jpg';
import Handels4 from '../../../assets/images/concepts/Materials/handels/4.jpg';
import Handels5 from '../../../assets/images/concepts/Materials/handels/5.jpg';
import Handels6 from '../../../assets/images/concepts/Materials/handels/6.jpg';
import Handels7 from '../../../assets/images/concepts/Materials/handels/7.jpg';
import Handels8 from '../../../assets/images/concepts/Materials/handels/8.jpg';
import Handels9 from '../../../assets/images/concepts/Materials/handels/9.jpg';
import Handels10 from '../../../assets/images/concepts/Materials/handels/10.jpg';
import Handels11 from '../../../assets/images/concepts/Materials/handels/11.jpg';
import Handels12 from '../../../assets/images/concepts/Materials/handels/12.jpg';
import Handels13 from '../../../assets/images/concepts/Materials/handels/13.jpg';
import Handels14 from '../../../assets/images/concepts/Materials/handels/14.jpg';
import Handels15 from '../../../assets/images/concepts/Materials/handels/15.jpg';
import Handels16 from '../../../assets/images/concepts/Materials/handels/16.jpg';
import Handels17 from '../../../assets/images/concepts/Materials/handels/17.jpg';
import Handels18 from '../../../assets/images/concepts/Materials/handels/18.jpg';
import Handels19 from '../../../assets/images/concepts/Materials/handels/19.jpg';
import Handels20 from '../../../assets/images/concepts/Materials/handels/20.jpg';

// handlerecesses import

import Handlerecesses1 from '../../../assets/images/concepts/Materials/handlerecesses/1.jpg';
import Handlerecesses2 from '../../../assets/images/concepts/Materials/handlerecesses/2.jpg';
import Handlerecesses3 from '../../../assets/images/concepts/Materials/handlerecesses/3.jpg';

// hingesandopeningsupportsystems import

import Hingesandopeningsupportsystems1 from '../../../assets/images/concepts/Materials/hingesandopeningsupportsystems/1.jpg';
import Hingesandopeningsupportsystems2 from '../../../assets/images/concepts/Materials/hingesandopeningsupportsystems/2.jpg';
import Hingesandopeningsupportsystems3 from '../../../assets/images/concepts/Materials/hingesandopeningsupportsystems/3.jpg';
import Hingesandopeningsupportsystems4 from '../../../assets/images/concepts/Materials/hingesandopeningsupportsystems/4.jpg';
import Hingesandopeningsupportsystems5 from '../../../assets/images/concepts/Materials/hingesandopeningsupportsystems/5.jpg';
import Hingesandopeningsupportsystems6 from '../../../assets/images/concepts/Materials/hingesandopeningsupportsystems/6.jpg';
import Hingesandopeningsupportsystems7 from '../../../assets/images/concepts/Materials/hingesandopeningsupportsystems/7.jpg';
import Hingesandopeningsupportsystems8 from '../../../assets/images/concepts/Materials/hingesandopeningsupportsystems/8.jpg';
import Hingesandopeningsupportsystems9 from '../../../assets/images/concepts/Materials/hingesandopeningsupportsystems/9.jpg';
import Hingesandopeningsupportsystems10 from '../../../assets/images/concepts/Materials/hingesandopeningsupportsystems/10.jpg';
import Hingesandopeningsupportsystems11 from '../../../assets/images/concepts/Materials/hingesandopeningsupportsystems/11.jpg';
import Hingesandopeningsupportsystems12 from '../../../assets/images/concepts/Materials/hingesandopeningsupportsystems/12.jpg';
import Hingesandopeningsupportsystems13 from '../../../assets/images/concepts/Materials/hingesandopeningsupportsystems/13.jpg';
import Hingesandopeningsupportsystems14 from '../../../assets/images/concepts/Materials/hingesandopeningsupportsystems/14.jpg';
import Hingesandopeningsupportsystems15 from '../../../assets/images/concepts/Materials/hingesandopeningsupportsystems/15.jpg';
import Hingesandopeningsupportsystems16 from '../../../assets/images/concepts/Materials/hingesandopeningsupportsystems/16.jpg';

// knobshandelsprofiles import

import Knobshandelsprofiles1 from '../../../assets/images/concepts/Materials/knobshandelsprofiles/1.jpg';
import Knobshandelsprofiles2 from '../../../assets/images/concepts/Materials/knobshandelsprofiles/2.jpg';
import Knobshandelsprofiles3 from '../../../assets/images/concepts/Materials/knobshandelsprofiles/3.jpg';
import Knobshandelsprofiles4 from '../../../assets/images/concepts/Materials/knobshandelsprofiles/4.jpg';
import Knobshandelsprofiles5 from '../../../assets/images/concepts/Materials/knobshandelsprofiles/5.jpg';
import Knobshandelsprofiles6 from '../../../assets/images/concepts/Materials/knobshandelsprofiles/6.jpg';
import Knobshandelsprofiles7 from '../../../assets/images/concepts/Materials/knobshandelsprofiles/7.jpg';
import Knobshandelsprofiles8 from '../../../assets/images/concepts/Materials/knobshandelsprofiles/8.jpg';
import Knobshandelsprofiles9 from '../../../assets/images/concepts/Materials/knobshandelsprofiles/9.jpg';
import Knobshandelsprofiles10 from '../../../assets/images/concepts/Materials/knobshandelsprofiles/10.jpg';
import Knobshandelsprofiles11 from '../../../assets/images/concepts/Materials/knobshandelsprofiles/11.jpg';
import Knobshandelsprofiles12 from '../../../assets/images/concepts/Materials/knobshandelsprofiles/12.jpg';
import Knobshandelsprofiles13 from '../../../assets/images/concepts/Materials/knobshandelsprofiles/13.jpg';
import Knobshandelsprofiles14 from '../../../assets/images/concepts/Materials/knobshandelsprofiles/14.jpg';
import Knobshandelsprofiles15 from '../../../assets/images/concepts/Materials/knobshandelsprofiles/15.jpg';
import Knobshandelsprofiles16 from '../../../assets/images/concepts/Materials/knobshandelsprofiles/16.jpg';

// liftsystems import

import Liftsystems1 from '../../../assets/images/concepts/Materials/liftsystems/1.jpg';
import Liftsystems2 from '../../../assets/images/concepts/Materials/liftsystems/2.jpg';
import Liftsystems3 from '../../../assets/images/concepts/Materials/liftsystems/3.jpg';
import Liftsystems4 from '../../../assets/images/concepts/Materials/liftsystems/4.jpg';
import Liftsystems5 from '../../../assets/images/concepts/Materials/liftsystems/5.jpg';
import Liftsystems6 from '../../../assets/images/concepts/Materials/liftsystems/6.jpg';
import Liftsystems7 from '../../../assets/images/concepts/Materials/liftsystems/7.jpg';
import Liftsystems8 from '../../../assets/images/concepts/Materials/liftsystems/8.jpg';
import Liftsystems9 from '../../../assets/images/concepts/Materials/liftsystems/9.jpg';

// profiles import

import Profiles1 from '../../../assets/images/concepts/Materials/profiles/1.jpg';
import Profiles2 from '../../../assets/images/concepts/Materials/profiles/2.jpg';
import Profiles3 from '../../../assets/images/concepts/Materials/profiles/3.jpg';
import Profiles4 from '../../../assets/images/concepts/Materials/profiles/4.jpg';
import Profiles5 from '../../../assets/images/concepts/Materials/profiles/5.jpg';

const products = [
  {
    id: 12,
    title: 'Lift Systems',
    previewUrl: Liftsystems1, // Remplacez par le chemin de votre image
    imageUrls: [Liftsystems1, Liftsystems2, Liftsystems3, Liftsystems4, Liftsystems5, Liftsystems6, Liftsystems7, Liftsystems8, Liftsystems9], //
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus.'
  },
  {
    id: 10,
    title: 'Hinges and Opening Support Systems',
    previewUrl: Hingesandopeningsupportsystems1, // Remplacez par le chemin de votre image
    imageUrls: [Hingesandopeningsupportsystems1, Hingesandopeningsupportsystems2, Hingesandopeningsupportsystems3, Hingesandopeningsupportsystems4, Hingesandopeningsupportsystems5, Hingesandopeningsupportsystems6, Hingesandopeningsupportsystems7, Hingesandopeningsupportsystems8, Hingesandopeningsupportsystems9, Hingesandopeningsupportsystems10, Hingesandopeningsupportsystems11, Hingesandopeningsupportsystems12, Hingesandopeningsupportsystems13, Hingesandopeningsupportsystems14, Hingesandopeningsupportsystems15, Hingesandopeningsupportsystems16], // Remplacez par les chemins de vos images
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus.'
  },
  {
    id: 6,
    title: 'Drawer Systems and Internal Organizations',
    previewUrl: Drawersystemsandinternalorganizations1, // Remplacez par le chemin de votre image
    imageUrls: [Drawersystemsandinternalorganizations1, Drawersystemsandinternalorganizations2, Drawersystemsandinternalorganizations3, Drawersystemsandinternalorganizations4, Drawersystemsandinternalorganizations6, Drawersystemsandinternalorganizations7, Drawersystemsandinternalorganizations8, Drawersystemsandinternalorganizations9, Drawersystemsandinternalorganizations10, Drawersystemsandinternalorganizations11, Drawersystemsandinternalorganizations12, Drawersystemsandinternalorganizations13, Drawersystemsandinternalorganizations14, Drawersystemsandinternalorganizations15, Drawersystemsandinternalorganizations16, Drawersystemsandinternalorganizations17, Drawersystemsandinternalorganizations18, Drawersystemsandinternalorganizations19, Drawersystemsandinternalorganizations20], // Remplacez par les chemins de vos images
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus.'
  },
  {
    id: 11,
    title: 'Knobs, Handles & Profiles',
    previewUrl: Knobshandelsprofiles1, // Remplacez par le chemin de votre image
    imageUrls: [Knobshandelsprofiles1, Knobshandelsprofiles2, Knobshandelsprofiles3, Knobshandelsprofiles4, Knobshandelsprofiles5, Knobshandelsprofiles6, Knobshandelsprofiles7, Knobshandelsprofiles8, Knobshandelsprofiles9, Knobshandelsprofiles10, Knobshandelsprofiles11, Knobshandelsprofiles12, Knobshandelsprofiles13, Knobshandelsprofiles14, Knobshandelsprofiles15, Knobshandelsprofiles16], //
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus.'
  },
  {
    id: 8,
    title: 'Handles',
    previewUrl: Handels1, // Remplacez par le chemin de votre image
    imageUrls: [Handels1, Handels2, Handels3, Handels4, Handels5, Handels6, Handels7, Handels8, Handels9, Handels10, Handels11, Handels12, Handels13, Handels14, Handels15, Handels16, Handels17, Handels18, Handels19, Handels20], // Remplacez par les chemins de vos images
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus.'
  },
  {
    id: 1,
    title: 'Accessories',
    previewUrl: Accessories1, // Remplacez par le chemin de votre image
    imageUrls: [Accessories1, Accessories2, Accessories3, Accessories4, Accessories5, Accessories6, Accessories7, Accessories8, Accessories9, Accessories10, Accessories11, Accessories12, Accessories13], // Remplacez par les chemins de vos images
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },
  {
    id: 2,
    title: 'Cabinet Boxes and Accessories',
    previewUrl: Cabinetboxesandaccessories1, // Remplacez par le chemin de votre image
    imageUrls: [Cabinetboxesandaccessories1, Cabinetboxesandaccessories2, Cabinetboxesandaccessories3], // Remplacez par les chemins de vos images
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },
  {
    id: 3,
    title: 'Materials for Fronts',
    previewUrl: Materialsforfronts1, // Remplacez par le chemin de votre image
    imageUrls: [Materialsforfronts1, Materialsforfronts2, Materialsforfronts3, Materialsforfronts4, Materialsforfronts5, Materialsforfronts6, Materialsforfronts7, Materialsforfronts8, Materialsforfronts9, Materialsforfronts10, Materialsforfronts11, Materialsforfronts12, Materialsforfronts13, Materialsforfronts14, Materialsforfronts15, Materialsforfronts16, Materialsforfronts17, Materialsforfronts18, Materialsforfronts19, Materialsforfronts20, Materialsforfronts21, Materialsforfronts22, Materialsforfronts23, Materialsforfronts24], // Remplacez par les chemins de vos images
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },
  {
    id: 4,
    title: 'Worktops and Accessories',
    previewUrl: Worktopsandaccessories2, // Remplacez par le chemin de votre image
    imageUrls: [Worktopsandaccessories2, Worktopsandaccessories4, Worktopsandaccessories5, Worktopsandaccessories6, Worktopsandaccessories7, Worktopsandaccessories8, Worktopsandaccessories9, Worktopsandaccessories11, Worktopsandaccessories12, Worktopsandaccessories14], // Remplacez par les chemins de vos images
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },
  {
    id: 5,
    title: 'Concealed Pulls & Grips',
    previewUrl: Concealedpullgrips1, // Remplacez par le chemin de votre image
    imageUrls: [Concealedpullgrips1, Concealedpullgrips2, Concealedpullgrips3, Concealedpullgrips4, Concealedpullgrips5, Concealedpullgrips6], // Remplacez par les chemins de vos images
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus.'
  },
  {
    id: 7,
    title: 'Edge Pulls',
    previewUrl: Edgepulls1, // Remplacez par le chemin de votre image
    imageUrls: [Edgepulls1, Edgepulls2, Edgepulls3, Edgepulls4, Edgepulls5], // Remplacez par les chemins de vos images
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus.'
  },
  {
    id: 9,
    title: 'Handle Recesses',
    previewUrl: Handlerecesses1, // Remplacez par le chemin de votre image
    imageUrls: [Handlerecesses1, Handlerecesses2, Handlerecesses3], // Remplacez par les chemins de vos images
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus.'
  },
  {
    id: 13,
    title: 'Profiles',
    previewUrl: Profiles1, // Remplacez par le chemin de votre image
    imageUrls: [Profiles1, Profiles2, Profiles3, Profiles4, Profiles5], //
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus.'
  },

];

function ProductDisplay() {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };
  return (
    <div className="product-display-container">
      {products.map((product) => (
        <div className="product-card" key={product.id} onClick={() => handleProductClick(product)}>
          <img src={product.previewUrl} alt={product.title} className="product-image" />
          <div className="product-info">
            <h2 className="product-title">{product.title}</h2>
          </div>
        </div>
      ))}
      {isModalOpen && <ProductModal product={selectedProduct} onClose={handleCloseModal} />}

    </div>
  );
}

export default ProductDisplay;
