/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react';

import '../Kitchens/kitchens.scss';
import ProductModal from '../productModal/productModal';

import HotelFurniture1 from '../../../assets/images/concepts/hotelfurniture/1.jpg';
import HotelFurniture2 from '../../../assets/images/concepts/hotelfurniture/2.jpg';
import HotelFurniture3 from '../../../assets/images/concepts/hotelfurniture/3.jpg';
import HotelFurniture4 from '../../../assets/images/concepts/hotelfurniture/4.jpg';
import HotelFurniture5 from '../../../assets/images/concepts/hotelfurniture/5.jpg';
import HotelFurniture6 from '../../../assets/images/concepts/hotelfurniture/6.jpg';
import HotelFurniture7 from '../../../assets/images/concepts/hotelfurniture/7.jpg';

const products = [
  {
    id: 1,
    title: 'Hotel furniture',
    previewUrl: HotelFurniture1, // Remplacez par le chemin de votre image
    imageUrls: [HotelFurniture1, HotelFurniture2, HotelFurniture3, HotelFurniture4, HotelFurniture5, HotelFurniture6, HotelFurniture7],
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.'
  },
];

function ProductDisplay() {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };
  return (
    <div className="product-display-container">
      {products.map((product) => (
        <div className="product-card" key={product.id} onClick={() => handleProductClick(product)}>
          <img src={product.previewUrl} alt={product.title} className="product-image" />
          <div className="product-info">
            <h2 className="product-title">{product.title}</h2>
          </div>
        </div>
      ))}
      {isModalOpen && <ProductModal product={selectedProduct} onClose={handleCloseModal} />}

    </div>
  );
}
export default ProductDisplay;
