/* eslint-disable max-len */
import './404.scss'; // Assurez-vous que le chemin vers le fichier SCSS est correct

function NotFoundPage() {
  return (
    <div className="not-found-container">
      <div className="content">
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <p>Sorry, the page you're looking for doesn't exist. If you think something is broken, report a problem.</p>
        <button type="button" onClick={() => window.history.back()}>Return Home</button>
      </div>
    </div>
  );
}

export default NotFoundPage;
