/* eslint-disable max-len */
// PrivacyPolicy.jsx

import './privacyPolicy.scss';
import { useEffect } from 'react';

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>

      <p>Copying information from the site is allowed only if there is an active hyperlink to the source.
        Privacy Policy ZOV Furniture L.L.C. values your privacy and security and wants to give you
        the best possible online experience.
        To that end, we have developed this policy to explain what type of information we collect,
        how it is used and protected, and how you can control its use.
        Please read this document carefully to understand our views and practices regarding your
        personal information and how we will treat it.
        ZOV Furniture L.L.C. is a trading name of ZOV FURNITURE Limited Liability Company.
        It is important you know that Zov Furniture LLC will never sell, trade, or rent your personal
        information to others without your consent.
        By visiting our websites, you are accepting and consenting to the practices described in this
        policy.
      </p>
      <h2>What is personal data?</h2>

      <p>Personal data means any information relating to your person by which you can be identified
        directly or indirectly (e.g. your name, your address, your telephone numbers, your e-mail
        address).
      </p>
      <h2>II. How does the collection and protection of personal data work?</h2>
      <h2>1) Who collects and protects my personal data?</h2>
      <p>
        The company ZOV Furniture L.L.C is responsible for processing personal data.
      </p>
      <h2>
        2) When does ZOV Furniture L.L.C. collect my personal data?
      </h2>
      <p>
        • When I fill out a collection form with my personal data or subscribe to the newsletter.
        When you request an appointment, create a customer area, subscribe to the newsletter or fill
        out a contact or application form, we collect your personal data:
        - To respond to your requests (requests for information, making appointments, etc.),
        - In order to allow you to benefit from the optimal functionalities of our site (creation of a
        customer area),
        - For survey and analysis purposes,
        - In order to send you, if you ask us, advertising catalogs, newsletters, commercial operations
        and direct marketing.

        The information which concerns you and which is collected from the forms which you
        complete is subject to computer processing intended for ZOV Furniture L.L.C.
        • When I call ZOV Furniture L.L.C Customer Service.
        If you call ZOV Furniture L.L.C. brand Customer Service, we collect your personal data in
        order to respond to your requests.
        We also inform you that your telephone conversation may be recorded by ZOV Furniture
        L.L.C. for reasons of improving the quality of our services.
        Only authorized Customer Service personnel as well as service providers and subcontractors
        involved in this purpose will have access to this recording.
        You can object to this recording by notifying your operator at the start of your call.
        • Via cookies
        Cookies installed on your terminal also allow ZOV Furniture L.L.C. to collect data about you
        and in particular to identify you. To learn more about cookies, please see paragraph IV.
      </p>
      <h2>
        3) What information is collected?
      </h2>
      <p>
        ZOV Furniture L.L.C. only collects the data that is necessary to process your requests. For
        each of your requests, the collection of certain data is essential. Thus, ZOV Furniture L.L.C.
        may find itself unable to respond to one of your requests in the event that you do not wish to
        provide the personal data necessary for its handling.
      </p>
      <h2>4) What is the purpose of the processing?</h2>
      <p>
        The data collected is used, as appropriate, to respond to your requests, for survey purposes,
        analyzes or commercial and marketing operations. This data can also be used to offer you a
        personalized display of the Site.
      </p>
      <h2>5) What is the legal basis for the processing?</h2>
      <p>
        The collection of personal data and the processing carried out by ZOV Furniture L.L.C. are
        based on the legitimate interest or consent of the data subject, as applicable.
      </p>
      <h2>6) Who are the recipients of the data collected?</h2>
      <p>
        The information collected is transferred to the stakeholders necessary to fully handle your
        requests. More precisely, the recipients are ZOV Furniture L.L.C. as well as its service
        providers and subcontractors involved in the context of the intended purpose and the
        Schmidt network dealers when necessary.
      </p>
      <h2>7) How long will my data be kept?</h2>
      <p>
        ZOV Furniture L.L.C. company keeps the data for the period necessary to accomplish the
        purpose for which they were collected, increased by the period allowing ZOV Furniture L.L.C.
        company to meet its legal obligations or a period authorized by law.
        The data collected to respond to your questions, complaints, requests, or applications is kept
        for the duration necessary to process or respond to your questions, complaints, requests, or
        applications, increased by the applicable legal limitation period.

        The data collected for prospecting purposes is kept for the duration during which the person
        concerned interacts with the Site or receives the newsletter to which they have subscribed,
        increased by a period of 3 years.
      </p>
      <h2>IV. How are cookies managed?</h2>
      <h2>1) What are “cookies”?</h2>
      <p>
        As part of the use of the Site, information relating to navigation may be recorded in text files
        called “cookies” installed on your terminal.
        Cookies cover all the trackers placed on your hard drive when you visit a website. They allow
        certain navigation data to be collected in order to facilitate the subsequent use of a website.
        The information contained in these cookies can only be read or modified by the issuer.
      </p>
      <h2>2) Why does ZOV Furniture L.L.C. use cookies?</h2>
      <p>
        Cookies make it easier for you to use the Site later by recording certain information relating
        to your browsing. Cookies are installed when you browse the Site.
        The cookies used by ZOV Furniture L.L.C. allow you to easily access your personal space via
        identifiers or information transmitted subsequently, to memorize certain information relating
        to a form completed by you on the Site.
      </p>
      <h2>3) What are my rights regarding the insertion of cookies?</h2>
      <p>
        You can configure the traceability of cookies on your internet browser (in your browser
        menu). Note that the configuration procedure differs depending on the browser used and
        must be carried out on each terminal used.
        You can therefore choose to accept or refuse the insertion of cookies when browsing the Site
        and your initial choice can be modified at any time.
        • If I accept the insertion of cookies
        The insertion of certain cookies is subject to your consent. Thus, after obtaining your
        consent, cookies may be stored on your hard drive. Only the issuer is likely to have access to
        them and modify them.
        • If I refuse the insertion of cookies
        If you refuse to save cookies subject to consent on your hard drive, certain navigation
        functionalities may be altered.
        Therefore, we decline all responsibility concerning the degraded functioning of the Site in the
        event of your refusal to insert the cookies necessary for appropriate navigation.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
