import React, { useEffect } from 'react';

function TypeFormEmbed() {
  useEffect(() => {
    // Charger le script Typeform
    const script = document.createElement('script');
    script.src = 'https://embed.typeform.com/next/embed.js';
    script.async = true;
    document.body.appendChild(script);

    // Nettoyage
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div style={{ width: '50%', margin: '0 auto', marginBottom: '2rem' }}>
      <div data-tf-live="01J4257HRAQ5SZA9DD6M3KAZ39" /><script src="//embed.typeform.com/next/embed.js" />
    </div>
  );
}

export default React.memo(TypeFormEmbed);
